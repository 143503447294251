import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent, CircularProgress } from '@mui/material';
import { db } from '../../firebase'; // Ensure Firebase is properly initialized
import { collection, getDocs } from 'firebase/firestore';
import { PRIMARYCOLOR } from '../../constants/data';
import { formatNumberWithCommas } from '../../Helper/Helper';

const SalesComponent = () => {
  const [salesData, setSalesData] = useState({
    totalSales: 0,
    completedSales: 0,
    pendingSales: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        let totalSales = 0;
        let completedSales = 0;
        let pendingSales = 0;

        // Helper function to parse numeric values
        const parseNumericValue = (value) => {
          return parseFloat(value.replace(/,/g, '') || 0);
        };

        // Fetch projectDeals
        const projectDealsSnapshot = await getDocs(collection(db, 'projectDeals'));
        projectDealsSnapshot.forEach((doc) => {
          const data = doc.data();
          const nettPrice = parseNumericValue(data.nettPrice);
          if (data.status === 8) {
            completedSales += nettPrice;
          } else {
            pendingSales += nettPrice;
          }
        });

        // Fetch subsaleDeals
        const subsaleDealsSnapshot = await getDocs(collection(db, 'subsaleDeals'));
        subsaleDealsSnapshot.forEach((doc) => {
          const data = doc.data();
          const purchasePrice = parseNumericValue(data.purchasePrice);
          if (data.status === 8) {
            completedSales += purchasePrice;
          } else {
            pendingSales += purchasePrice;
          }
        });

        // Fetch rentalDeals
        const rentalDealsSnapshot = await getDocs(collection(db, 'rentalDeals'));
        rentalDealsSnapshot.forEach((doc) => {
          const data = doc.data();
          const rentalPerMonth = parseNumericValue(data.rentalPerMonth);
          if (data.status === 8) {
            completedSales += rentalPerMonth;
          } else {
            pendingSales += rentalPerMonth;
          }
        });

        // Calculate Total Sales
        totalSales = completedSales + pendingSales;

        // Update State
        setSalesData({ totalSales, completedSales, pendingSales });
      } catch (error) {
        console.error('Error fetching sales data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSalesData();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        {[
          { title: 'Sales', currency: 'RM', value: salesData.totalSales.toFixed(2), color: PRIMARYCOLOR },
          { title: 'Completed', currency: 'RM', value: salesData.completedSales.toFixed(2), color: PRIMARYCOLOR },
          { title: 'Pending', currency: 'RM', value: salesData.pendingSales.toFixed(2), color: PRIMARYCOLOR },
        ].map((item, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Card
              sx={{
                backgroundColor: item.color,
                color: '#fff',
                textAlign: 'left',
                borderRadius: '8px',
                padding: 2,
              }}
            >
              <CardContent sx={{ padding: 0 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold', marginBottom: 1, fontSize: '24px' }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: '1.1rem', marginBottom: 0.5, fontSize: '24px' }}
                >
                  {item.currency}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 'bold',
                    marginTop: 1,
                    fontSize: '36px',
                    paddingLeft: '50px',
                  }}
                >
                  {formatNumberWithCommas(item.value)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SalesComponent;
