import React, { useState, useMemo, useEffect } from 'react';
import { Collapse, Box, CssBaseline, Drawer, AppBar, Toolbar, List, Typography, ListItem, ListItemIcon, ListItemText, IconButton, Button, Badge } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import { AccountCircle, Apartment, BeachAccess, Email, GroupAdd, Phone } from '@mui/icons-material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DescriptionIcon from '@mui/icons-material/Description';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth, db } from '../firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { LogoutRounded } from '@mui/icons-material';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { useAuth } from '../context/AuthContext'; 
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const drawerWidth = 240;

const Layouts = ({ children }) => {
  const { userData } = useAuth(); 
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [pendingRequestsCount, setPendingRequestsCount] = useState(0);
  const [moreOpen, setMoreOpen] = useState(false); 

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Real-time listener for pending requests count
  useEffect(() => {
    const collections = ['projectDeals', 'rentalDeals', 'subsaleDeals'];
    const unsubscribeListeners = [];
  
    const updatePendingRequestsCount = () => {
      let collectionCounts = {}; // To store counts for each collection
  
      collections.forEach((coll) => {
        const q = query(collection(db, coll), where('status', '==', 3));
  
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          // Update the count for the current collection
          collectionCounts[coll] = querySnapshot.size;
  
          // Calculate the total pending count by summing up all collection counts
          const totalPendingCount = Object.values(collectionCounts).reduce((sum, count) => sum + count, 0);
          
          // Update the state with the final total
          setPendingRequestsCount(totalPendingCount);
        });
  
        unsubscribeListeners.push(unsubscribe);
      });
    };
  
    updatePendingRequestsCount();
  
    // Cleanup listeners on unmount
    return () => {
      unsubscribeListeners.forEach((unsubscribe) => unsubscribe());
    };
  }, []);
  
  const getTitle = (path) => {
    if (path.startsWith('/sales/create-project-deal')) return 'Add New Project Deal';
    if (path.startsWith('/sales/create-rental-deal')) return 'Add New Rental Deal';
    if (path.startsWith('/sales/create-subsale-deal')) return 'Add New Subsale Deal';
    if (path.startsWith('/sales/view-subsale-deal')) return 'View Subsale Deal';
    if (path.startsWith('/sales/view-project-deal')) return 'View Project Deal';
    if (path.startsWith('/sales/view-rental-deal')) return 'View Rental Deal';
    if (path.startsWith('/sales/edit-subsale-deal')) return 'Edit Subsale Deal';
    if (path.startsWith('/sales/edit-project-deal')) return 'Edit Project Deal';
    if (path.startsWith('/sales/edit-rental-deal')) return 'Edit Rental Deal';
    switch (path) {
      case '/': return 'Home';
      case '/documents': return 'Documents';
      case '/users': return 'User';
      case '/projects': return 'Project List';
      case '/profile': return 'Profile';
      case '/commissions': return 'My Commissions';
      case '/requests': return 'Requests';
      case '/sales': return 'Sales';
      case '/office-slots': return 'Office Slots';
      case '/leaves': return 'Leaves';
      case '/recruitment': return 'Recruitments';
      case '/contacts': return 'Contacts';
      case '/email-template': return 'Email Template';
      default: return 'Home';
    }
  };

  const title = useMemo(() => getTitle(location.pathname), [location.pathname]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const isActive = (link) => {
    if (link === '/') return location.pathname === link;
    return location.pathname.startsWith(link);
  };

  const handleMoreClick = () => {
    setMoreOpen(!moreOpen);
  };

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap>LSV System</Typography>
      </Toolbar>
      <List>
        {[
          { text: 'Home', icon: <HomeIcon />, link: '/' },
          { text: 'Projects', icon: <Apartment />, link: '/projects' },
          { text: 'Sales', icon: <AttachMoneyIcon />, link: '/sales' },
          { text: 'Commissions', icon: <AccountBalanceWalletIcon />, link: '/commissions' },
          ...(userData?.position === 'Admin' ? [{
            text: 'Requests',
            icon: (
              <Badge badgeContent={pendingRequestsCount} color="error">
                <AssignmentLateIcon />
              </Badge>
            ),
            link: '/requests'
          }] : []),
          ...(userData?.position === 'Admin' ? [{ text: 'User', icon: <PersonIcon />, link: '/users' }] : []), // Only show "User" tab for Admin
          { text: 'Profile', icon: <AccountCircle />, link: '/profile' },
        ].map((item) => (
          <ListItem
            key={item.text}
            component={Link}
            to={item.link}
            listItemButton={isActive(item.link)}
            sx={{
              backgroundColor: isActive(item.link) ? 'primary.light' : '',
              '&:hover': { backgroundColor: 'primary.light' },
              color: isActive(item.link) ? 'white' : 'text.primary',
            }}
          >
            <ListItemIcon sx={{ color: isActive(item.link) ? 'white' : 'text.primary' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}

        <ListItem button onClick={handleMoreClick}>
          <ListItemIcon>
            <MenuIcon />
          </ListItemIcon>
          <ListItemText primary="More" />
          {moreOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={moreOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {[
              { text: 'Documents', icon: <DescriptionIcon />, link: '/documents' },
              { text: 'Office Slots', icon: <CalendarMonthIcon />, link: '/office-slots' },
              { text: 'Leaves', icon: <BeachAccess />, link: '/leaves' },
              { text: 'Recruitments', icon: <GroupAdd />, link: '/recruitment' },
              { text: 'Contacts', icon: <Phone />, link: '/contacts' },
              ...(userData?.position === 'Admin'
                ? [{ text: 'Email Templates', icon: <Email />, link: '/email-template' }]
                : []), 
            ].map((item) => (
              <ListItem
                key={item.text}
                component={Link}
                to={item.link}
                listItemButton={isActive(item.link)}
                sx={{
                  pl: 4, // Indent child items
                  backgroundColor: isActive(item.link) ? 'primary.light' : '',
                  '&:hover': { backgroundColor: 'primary.light' },
                  color: isActive(item.link) ? 'white' : 'text.primary',
                }}
              >
                <ListItemIcon sx={{ color: isActive(item.link) ? 'white' : 'text.primary' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: 'primary.light'
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">{title}</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Typography variant="body1" component="span" sx={{ marginRight: 2 }}>
            {userData ? `Welcome, ${userData.name}` : 'Hello, Guest'}
          </Typography>
          <Button
            color="inherit"
            onClick={handleLogout}
            startIcon={<LogoutRounded />}
            sx={{
              border: '1px solid white',
              borderRadius: 1,
              padding: '6px 16px',
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default Layouts;
