import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper } from '@mui/material';

const TermsConditionTable = ({ data, commencementDate }) => {
  return (
    <Box >
      <Typography variant="h5" gutterBottom sx={{ textAlign: 'left', fontWeight: 'bold', textDecoration: 'underline', mb: 5 }}>
        Terms & Condition
      </Typography>

      {/* Title */}
      <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold', textDecoration: 'underline' }}>
        Official Commission Tiers for Subsales & Rental
      </Typography>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Commission Tiers</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>Target & Team Leader Overriding</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>HOP’s Overriding</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">{row.commissionTier}</TableCell>
                <TableCell align="center">{row.targetLeaderOverriding}</TableCell>
                <TableCell align="center">{row.hopsOverriding}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Commencement Date */}
      <Typography variant="body2" sx={{ marginTop: 2, fontStyle: 'italic', textAlign: 'right' }}>
        Commencement Date: {commencementDate}
      </Typography>

      {/* Additional Statements */}
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="body1" gutterBottom>
          i. Agent must agree and comply with the above commission tiers set by the company.
        </Typography>
        <Typography variant="body1" gutterBottom>
          ii. For rental claim, agent must attach complete documents such as <strong>rental booking form</strong>, 
          <strong> stamped tenancy agreement</strong>, <strong>total transaction slips</strong> paid to company & any other related documents to LSV System.
        </Typography>
        <Typography variant="body1" gutterBottom>
          iii. For subsales claim, agent must attach complete documents such as <strong>subsales booking form</strong>, 
          <strong>stamped sales & purchase agreement</strong>, <strong>total transaction slips</strong> paid to company & any other related documents to LSV System.
        </Typography>
        <Typography variant="body1" gutterBottom>
          iv. Agents are responsible to complete this form and remit it to the administered department on time to request payment. 
          Please allow five (5) business days for processing. If failed to do any of the above, company is not to be held responsible for any loss, damage, expense, or delay.
        </Typography>
      </Box>
    </Box>
  );
};

const termsData = [
    {
      commissionTier: 'First Tier: 70%',
      targetLeaderOverriding: 'RM0 to RM29,999 (10% belongs to Leader)',
      hopsOverriding: '4% (subsales overriding) & 5% (rental overriding) based on nett commission from the agents.',
    },
    {
      commissionTier: 'Second Tier: 75%',
      targetLeaderOverriding: 'RM30,000 to RM84,999 (5% belongs to Leader)',
      hopsOverriding: '4% (subsales overriding) & 5% (rental overriding) based on nett commission from the agents.',
    },
    {
      commissionTier: 'Third Tier: 80%',
      targetLeaderOverriding: 'RM85,000 to RM134,999 (3% belongs to Leader)',
      hopsOverriding: '4% (subsales overriding) & 5% (rental overriding) based on nett commission from the agents.',
    },
    {
      commissionTier: 'Final Tier: 85%',
      targetLeaderOverriding: 'RM135,000 onwards (NIL)',
      hopsOverriding: '4% (subsales overriding) & 5% (rental overriding) based on nett commission from the agents.',
    },
  ];

  const TermsConditionComponent = () => {
    return (
      <TermsConditionTable
        data={termsData}
        commencementDate="7th February 2023"
      />
    );
  };
  
  export default TermsConditionComponent;