import React, { useEffect, useState } from 'react';
import { CircularProgress, Box, Grid, Typography, Button, Avatar, TextField, CardContent, Card, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, getDocs, collection, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { sendEmail } from '../../../Helper/EmailNotification';
import { fetchEmailTemplates } from '../../../services/Services';

const ViewRequestRental = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [rentalData, setRentalData] = useState(null);
  const [agentNames, setAgentNames] = useState({});
  const [emailTemplates, setEmailTemplates] = useState({}); // Store email templates
  const [openModal, setOpenModal] = useState(false); // Modal state for comments
  const [comments, setComments] = useState(''); // State for admin comments
  const [isLoading, setIsLoading] = useState(false);
  const [usersData, setUsersData] = useState({});

  useEffect(() => {
    const fetchRentalData = async () => {
      try {
        const docRef = doc(db, 'rentalDeals', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setRentalData(docSnap.data());
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching rental data:', error);
      }
    };

    const fetchUsersData = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const querySnapshot = await getDocs(usersCollection);
        const users = {};
        const names = {};

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          users[doc.id] = data; // Store full user data
          names[doc.id] = data.name; // Store agent names separately
        });

        setUsersData(users);
        setAgentNames(names);
      } catch (error) {
        console.error('Error fetching users data:', error);
      }
    };

    const loadEmailTemplates = async () => {
      try {
        const templates = await fetchEmailTemplates(); // Use the reusable service function
        setEmailTemplates(templates);
      } catch (error) {
        console.error('Error loading email templates:', error);
      }
    };

    fetchRentalData();
    fetchUsersData();
    loadEmailTemplates();
  }, [id]);

  if (!rentalData) {
    return <Typography>Loading...</Typography>;
  }

  const handleBackButton = () => {
    navigate('/requests', { state: { tabIndex: 0 } });
  };

  const handleStatusUpdate = async (newStatus, adminComments = '') => {
    setIsLoading(true);
    try {
      const docRef = doc(db, 'rentalDeals', id);
      await updateDoc(docRef, { status: newStatus, comments: adminComments });
      setRentalData((prevData) => ({ ...prevData, status: newStatus, comments: adminComments }));

      
      // Create claims for agents
      if (rentalData.salesAgentShare && rentalData.salesAgentShare.length > 0) {
        const emailTemplateKey = newStatus === 7 ? 'Sales_Approved' : 'Sales_Returned';
        const emailTemplate = emailTemplates[emailTemplateKey];

        const rentalPerMonth = Number(rentalData.rentalPerMonth.replace(/,/g, ''));
        const serviceFee = Number(rentalData.serviceFee);
        const commissionBase = rentalPerMonth * serviceFee;
        const agentClaims = rentalData.salesAgentShare.map((agent) => {
          const user = usersData[agent.uid];
          console.log(typeof user.subsalesTier, user.subsalesTier);
          const rentalTier = user.subsalesTier / 100; // Convert to decimal
          console.log(rentalTier);
          const sharePercentage = Number(agent.sharePercentage) / 100;

          let commissionAmount = 0;

          // Commission Calculation
          if (rentalData.salesAgentShare.length === 1) {
            commissionAmount = commissionBase * rentalTier;
          } else {
            commissionAmount = commissionBase * rentalTier * sharePercentage;
          }

          return {
            agentUid: agent.uid,
            agentName: user.name || 'Unknown Agent',
            commissionAmount: parseFloat(commissionAmount.toFixed(2)),
            commissionPercentage: sharePercentage * 100,
            rentalTier: rentalTier * 100,
            isClaimed: false,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          };
        });

        const rentalClaimRef = doc(db, 'rentalClaims', id);
        await setDoc(rentalClaimRef, {
          rentalDealId: rentalData.rentalDealId,
          agentClaims: agentClaims,
          createdAt: new Date().toISOString(),
        });

        if (emailTemplate) {
          for (const agent of rentalData.salesAgentShare) {
            const userDetails = usersData[agent.uid];
            if (userDetails && userDetails.email) {
              const personalizedMessage = emailTemplate.body
                .replace('{{salesId}}', rentalData.rentalDealId)
                .replace('{{comments}}', adminComments);
              await sendEmail(userDetails, personalizedMessage, emailTemplate.subject);
            } else {
              console.error(`User details not found or email missing for agent UID: ${agent.uid}`);
            }
          }
        }

        console.log('Rental claims successfully created.');
      }

      alert(`Status updated to ${newStatus === 7 ? 'Converted' : 'Returned  with comments.'}`);
      navigate('/requests', { state: { tabIndex: 0 } });
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Failed to update status. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setComments(''); // Reset comments
  };

  const handleSubmitReturn = () => {
    handleStatusUpdate(6, comments); // Update status to Returned (6) with comments
    handleCloseModal(); // Close the modal
  };

  const renderDocumentField = (label, url) => (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      value={url ? 'View Document' : 'N/A'}
      InputProps={{
        readOnly: true,
        endAdornment: url && (
          <Button variant="text" href={url} target="_blank">
            View
          </Button>
        ),
      }}
      sx={{ mb: 2 }}
    />
  );

  return (
    <Box sx={{ p: 3, border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#fff' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackButton}
        sx={{ mb: 2, marginBottom: '50px' }}
      >
        Back
      </Button>
      <Typography variant="h6" gutterBottom sx={{ mb: 2, marginBottom: '20px' }}>
        Rental Deal Information
      </Typography>
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Landlord Name"
            variant="outlined"
            fullWidth
            value={rentalData.landlordName || 'N/A'}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Tenant Name"
            variant="outlined"
            fullWidth
            value={rentalData.tenantName || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Project Name"
            variant="outlined"
            fullWidth
            value={rentalData.projectName || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Lot Number"
            variant="outlined"
            fullWidth
            value={rentalData.lotNumber || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </Grid>

        {/* Middle Column */}
        <Grid item xs={12} sm={4}>
        <TextField
            label="Service Fee (Months)"
            variant="outlined"
            fullWidth
            value={rentalData.serviceFee || 'N/A'}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Years of Tenure"
            variant="outlined"
            fullWidth
            value={rentalData.yearsOfTenure ? `${rentalData.yearsOfTenure} ${rentalData.yearsOfTenure > 1 ? 'Years' : 'Year'}` : 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Booking Fees (RM)"
            variant="outlined"
            fullWidth
            value={rentalData.bookingFees || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Total Rental Deposit (RM)"
            variant="outlined"
            fullWidth
            value={rentalData.totalRentalDeposit || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Rental Per Month (RM)"
            variant="outlined"
            fullWidth
            value={rentalData.rentalPerMonth || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Security Deposit (RM)"
            variant="outlined"
            fullWidth
            value={rentalData.securityDeposit || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Access Card Deposit (RM)"
            variant="outlined"
            fullWidth
            value={rentalData.accessCardDeposit || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Utility Deposit (RM)"
            variant="outlined"
            fullWidth
            value={rentalData.utilityDeposit || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Stamping Fees (RM)"
            variant="outlined"
            fullWidth
            value={rentalData.stampingFees || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="SST (RM)"
            variant="outlined"
            fullWidth
            value={rentalData.sst || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} sm={4}>
          {renderDocumentField('Rental Form', rentalData.rentalFormUploaded)}
          {renderDocumentField('Transaction Slip 1', rentalData.transactionSlip1Uploaded)}
          {renderDocumentField('Transaction Slip 2', rentalData.transactionSlip2Uploaded)}
          {renderDocumentField('Tenancy Agreement', rentalData.tenancyAgreementUploaded)}
          {renderDocumentField('Stamping Certificate', rentalData.stampingCertUploaded)}
        </Grid>
      </Grid>

      {/* Agents Information */}
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Agents Information
          </Typography>
          {rentalData.salesAgentShare.map((agent, index) => (
            <Box key={index} display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Avatar sx={{ bgcolor: '#3f51b5', mr: 2 }}>
                {agentNames[agent.uid]?.[0] || 'A'}
              </Avatar>
              <Box>
                <Typography>
                  Sales Agent: {agentNames[agent.uid] || agent.uid}
                </Typography>
                <Typography>
                  Commission Share: {agent.sharePercentage}%
                </Typography>
              </Box>
            </Box>
          ))}
          <TextField
            label="Remarks"
            variant="outlined"
            fullWidth
            value={rentalData.remark || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </CardContent>
      </Card>

      {/* Status and Action Buttons */}
      <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleStatusUpdate(7)}
        >
          {isLoading ?  <CircularProgress /> : 'Convert'}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleOpenModal}
        >
          Return
        </Button>
      </Box>

      {/* Comments Modal */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Provide Comments for Return</DialogTitle>
        <DialogContent>
          <TextField
            label="Comments"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitReturn} color="error">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ViewRequestRental;
