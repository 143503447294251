import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
  Box,
  Button,
  CircularProgress,
  TextField,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { useAuth } from '../../context/AuthContext';
import { storage, db } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';

const ProfilePage = () => {
  const { userData, currentUser } = useAuth();
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [editableFields, setEditableFields] = useState({});
  const [updatedData, setUpdatedData] = useState({});
  const [loadingField, setLoadingField] = useState(null); // Track which field is being updated
  const [successMessage, setSuccessMessage] = useState(''); // Snackbar message

  if (!userData) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  const {
    name,
    email,
    contact,
    position,
    gender,
    joinedDate,
    imageUrl,
    salary,
    medLeaves,
    annualLeaves,
    subsalesTier,
    projectTier,
  } = userData;

  const handleEditClick = (field) => {
    setEditableFields((prev) => ({ ...prev, [field]: true }));
  };

  const handleSaveClick = async (field) => {
    setLoadingField(field); // Start loading for this field
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { [field]: updatedData[field] });
      setEditableFields((prev) => ({ ...prev, [field]: false }));
      setSuccessMessage(`${field.charAt(0).toUpperCase() + field.slice(1)} updated successfully!`);

      // Refresh the page after successful update
      window.location.reload();
    } catch (error) {
      console.error(`Failed to update ${field}:`, error);
      setError(`Failed to update ${field}. Please try again.`);
    } finally {
      setLoadingField(null); // Stop loading for this field
    }
  };

  const handleInputChange = (field, value) => {
    setUpdatedData((prev) => ({ ...prev, [field]: value }));
  };

  // Function to get initials if imageUrl is not provided
  const getInitials = (name) => {
    if (!name) return '';
    return name
      .split(' ')
      .map((n) => n[0])
      .join('')
      .toUpperCase();
  };

  // Handle file upload
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `profilePictures/${currentUser.uid}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setUploading(true);
    setError(null);

    uploadTask.on(
      'state_changed',
      null,
      (error) => {
        setUploading(false);
        setError('File upload failed. Please try again.');
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        const userDocRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userDocRef, { imageUrl: downloadURL });

        setUploading(false);
        setError(null);
        window.location.reload();
      }
    );
  };

  return (
    <Box sx={{ padding: 4, backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      <Card sx={{ maxWidth: 1000, margin: 'auto', padding: 3, boxShadow: 2 }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: 'bold', marginBottom: 3, textAlign: 'center' }}
        >
          User Profile
        </Typography>

        <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
          <Avatar
            alt={name}
            src={imageUrl || ''}
            sx={{
              width: 120,
              height: 120,
              fontSize: 40,
              margin: '0 auto',
              backgroundColor: '#3f51b5',
            }}
          >
            {!imageUrl && getInitials(name)}
          </Avatar>
          <Box mt={2}>
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              id="upload-profile-picture"
              onChange={handleFileChange}
            />
            <label htmlFor="upload-profile-picture">
              <Button variant="contained" component="span" disabled={uploading}>
                {uploading ? <CircularProgress size={24} /> : 'Edit Picture'}
              </Button>
            </label>
          </Box>
          {error && <Typography color="error">{error}</Typography>}
        </Box>

        <CardContent>
          <Grid container spacing={3}>
            {[
              { label: 'Name', value: name, field: 'name', editable: true },
              { label: 'Position', value: position, field: 'position', editable: false },
              { label: 'Email', value: email, field: 'email', editable: true },
              { label: 'Salary', value: `RM ${salary}`, field: 'salary', editable: false },
              { label: 'Contact', value: contact, field: 'contact', editable: true },
              { label: 'Subsales Tier', value: `${subsalesTier}`, field: 'subsalesTier', editable: false },
              {
                label: 'Joined Date',
                value: joinedDate ? new Date(joinedDate).toLocaleDateString() : 'N/A',
                field: 'joinedDate',
                editable: false,
              },
              { label: 'Project Tier', value: `${projectTier}`, field: 'projectTier', editable: false },
            ].map(({ label, value, field, editable }) => (
              <Grid item xs={12} sm={6} key={field}>
                <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
                  <TextField
                    label={label}
                    fullWidth
                    value={
                      editable && editableFields[field]
                        ? updatedData[field] || value
                        : value || 'N/A'
                    }
                    InputProps={{ readOnly: !editable || !editableFields[field] }}
                    onChange={(e) =>
                      editable &&
                      editableFields[field] &&
                      handleInputChange(field, e.target.value)
                    }
                  />
                  {editable && (
                    editableFields[field] ? (
                      <IconButton
                        color="primary"
                        onClick={() => handleSaveClick(field)}
                        disabled={loadingField === field}
                      >
                        {loadingField === field ? (
                          <CircularProgress size={20} />
                        ) : (
                          <SaveIcon />
                        )}
                      </IconButton>
                    ) : (
                      <IconButton
                        color="primary"
                        onClick={() => handleEditClick(field)}
                      >
                        <EditIcon />
                      </IconButton>
                    )
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>

      {/* Snackbar for success messages */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage('')}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProfilePage;
