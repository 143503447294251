import React, { useState, useEffect } from "react";
import TableComponent from "../../components/TableComponent";
import {IconButton,Typography,Button,Box,TextField} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../context/AuthContext";
import { db, storage } from "../../firebase";
import {addDoc,collection,onSnapshot,doc,updateDoc} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import CustomModal from "../../components/CustomModal";
import ConfirmationModal from "../../components/ConfirmationModa";

const AnnouncementsPage = ({ userData }) => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [targetAnnouncementId, setTargetAnnouncementId] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [saving, setSaving] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const { currentUser } = useAuth();

  const columns = [
    { id: "no", label: "No.", minWidth: 50, align: "center" },
    { id: "title", label: "Title", minWidth: 200, align: "left" },
    {
      id: "action",
      label: "Action",
      minWidth: 150,
      align: "center",
      render: (row) => (
        <>
          <IconButton 
            color="primary"
            onClick={() => navigate(`/announcement/${row.id}`)}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            component="a"
            href={row.documentUrl}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            <DownloadIcon />
          </IconButton>
          {userData?.position === "Admin" && ( // Render Delete Icon for Admin Only
            <IconButton
              color="error"
              onClick={() => openConfirmationModal(row.id)} // Open the confirmation modal
            >
              <DeleteIcon />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onSnapshot(
      collection(db, "announcements"),
      (querySnapshot) => {
        const fetchedAnnouncements = querySnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
  
        setAnnouncements(fetchedAnnouncements);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching announcements in real-time:", error);
        alert("Failed to fetch announcements. Please try again.");
      }
    );
  
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSaveAnnouncement = async () => {
    if (!title || !description || !file) {
      alert("Please fill all fields and upload a file.");
      return;
    }

    setSaving(true);

    try {
      // Upload the file to Firebase Storage
      const fileRef = ref(storage, `announcements/${file.name}`);
      await uploadBytes(fileRef, file);
      const documentUrl = await getDownloadURL(fileRef);

      // Save announcement to Firestore
      const announcementData = {
        title,
        description,
        documentUrl,
        createdAt: new Date(),
        createdBy: currentUser.uid,
        deletedAt: null,
        deletedBy: null,
        isDeleted: 0,
      };

      await addDoc(collection(db, "announcements"), announcementData);

      // Add to local state
      setAnnouncements((prev) => [...prev, announcementData]);

      // Reset fields and close modal
      setTitle("");
      setDescription("");
      setFile(null);
      setModalOpen(false);
    } catch (error) {
      console.error("Error saving announcement:", error);
      alert("Failed to save announcement. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  const openConfirmationModal = (announcementId) => {
    setTargetAnnouncementId(announcementId);
    setConfirmationModalOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    if (!targetAnnouncementId) return;

    try {
      // Reference the document
      const announcementDoc = doc(db, "announcements", targetAnnouncementId);

      // Update the isDeleted field and add deletion metadata
      await updateDoc(announcementDoc, {
        isDeleted: 1,
        deletedAt: new Date(),
        deletedBy: currentUser.uid,
      });

      // Update the local state to reflect the change
      setAnnouncements((prev) =>
        prev.map((item) =>
          item.id === targetAnnouncementId
            ? { ...item, isDeleted: 1, deletedAt: new Date(), deletedBy: currentUser.uid }
            : item
        )
      );
    } catch (error) {
      console.error("Error deleting announcement:", error);
      alert("Failed to delete announcement. Please try again.");
    } finally {
      setConfirmationModalOpen(false);
      setTargetAnnouncementId(null);
    }
  };

  const handleCancelConfirmation = () => {
    setConfirmationModalOpen(false);
    setTargetAnnouncementId(null);
  };

  const filteredAnnouncements = announcements.filter(
    (item) =>
      item.isDeleted === 0 &&
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <Box>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            padding: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Announcement
          {userData?.position === "Admin" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModalOpen(true)}
            >
              Add Announcement
            </Button>
          )}
        </Typography>
      </Box>
      <SearchInput
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search..."
      />
      <TableComponent
        columns={columns}
        data={filteredAnnouncements} // Filter announcements
        loading={loading}
      />

      <CustomModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleSaveAnnouncement}
        title="Add Announcement"
        saving={saving}
      >
        <TextField
          fullWidth
          label="Title"
          margin="normal"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          fullWidth
          label="Description"
          margin="normal"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Button variant="contained" component="label" sx={{ mt: 2 }}>
          Upload File
          <input
            type="file"
            hidden
            onChange={handleFileUpload}
          />
        </Button>
        {file && <Typography sx={{ mt: 1 }}>{file.name}</Typography>}
      </CustomModal>

      <ConfirmationModal
        open={confirmationModalOpen}
        onClose={handleCancelConfirmation}
        onConfirm={handleDeleteConfirmation}
        title="Confirm Deletion"
        message="Are you sure you want to delete this announcement?"
      />
    </div>
  );
};

export default AnnouncementsPage;
