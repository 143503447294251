import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Button, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import TableComponent from '../../components/TableComponent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../context/AuthContext';
import { StatusWrapper } from '../../components/StatusWrapper';
import SearchInput from '../../components/SearchInput';

const RequestCommissionComponent = () => {
  const navigate = useNavigate();
  const { currentUser, userData } = useAuth();
  const [commissionData, setCommissionData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]); // State to hold the filtered data
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  // Mapping collection names to route paths
  const routeMapping = {
    projectDeals: '/request/view-project-deal',
    rentalDeals: '/request/view-rental-deal',
    subsaleDeals: '/request/view-subsale-deal',
  };

  useEffect(() => {
    setLoading(true);
    const unsubscribes = [];
    const collections = ['projectDeals', 'rentalDeals', 'subsaleDeals'];

    let allData = [];
    const statusMap = {};
    const usersMap = {};

    // Subscribe to the "status" collection
    const statusUnsub = onSnapshot(collection(db, 'status'), (statusSnapshot) => {
      statusSnapshot.docs.forEach((doc) => {
        statusMap[doc.id] = doc.data().Name;
      });
    });
    unsubscribes.push(statusUnsub);

    // Subscribe to the "users" collection
    const usersUnsub = onSnapshot(collection(db, 'users'), (usersSnapshot) => {
      usersSnapshot.docs.forEach((doc) => {
        usersMap[doc.id] = doc.data().name;
      });
    });
    unsubscribes.push(usersUnsub);

    // Subscribe to each collection and handle updates
    collections.forEach((coll) => {
      const collUnsub = onSnapshot(collection(db, coll), (snapshot) => {
        const data = snapshot.docs
          .map((doc, index) => {
            const dealIdField =
              coll === 'projectDeals'
                ? 'projectDealId'
                : coll === 'rentalDeals'
                ? 'rentalDealId'
                : 'subsaleDealId';
            return {
            id: doc.id,
            no: index + 1,
            collection: coll,
            type: coll === 'projectDeals' ? 'Project' : coll === 'rentalDeals' ? 'Rental' : 'Subsale',
            dealId: doc.data()[dealIdField],
            ...doc.data(),
          }})
          .filter((doc) => doc.status === 3); // Adjust the filter as needed

        // Update the data dynamically
        allData = [...allData.filter((item) => item.collection !== coll), ...data];

        const enrichedData = allData.map((item) => ({
          ...item,
          requestor: usersMap[item.createdBy] || 'Unknown',
          requestedAt: item.updatedAt ? new Date(item.updatedAt).toLocaleDateString() : 'N/A',
          status1: statusMap[item.status] || 'Unknown Status',
        }));

        // Set the data based on the user's position
        if (userData.position === 'Admin') {
          setCommissionData(enrichedData);
        } else {
          setCommissionData(enrichedData.filter((item) => item.createdBy === currentUser.uid));
        }
      });
      unsubscribes.push(collUnsub);
    });

    setLoading(false);

    return () => {
      unsubscribes.forEach((unsub) => unsub());
    };
  }, [currentUser, userData]);

  useEffect(() => {
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      const filtered = commissionData.filter(
        (item) =>
          item.requestor.toLowerCase().includes(lowerSearchTerm) ||
          item.dealId.toLowerCase().includes(lowerSearchTerm)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(commissionData); // Reset to full data when search term is empty
    }
  }, [searchTerm, commissionData]);

  // Handlers for the action buttons
  const handleView = (row) => {
    const route = `${routeMapping[row.collection]}/${row.id}`;
    navigate(route, { state: { fromRequests: true } });
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  // Define columns for the table
  const columns = [
    { id: 'no', label: 'No.', minWidth: 50 },
    { id: 'requestor', label: 'Requestor', minWidth: 200 },
    { id: 'dealId', label: 'Deal ID', minWidth: 100 },
    { id: 'type', label: 'Type', minWidth: 100 }, // New "Type" column
    { id: 'requestedAt', label: 'Requested At', minWidth: 100 },
    {
      id: 'status1',
      label: 'Status',
      minWidth: 150,
      render: (row) => StatusWrapper(row.status, row.status1),
    },
    {
      id: 'action',
      label: 'Action',
      minWidth: 200,
      render: (row) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <IconButton color="primary" onClick={() => handleView(row)}>
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Paper>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        Commission Requests
      </Typography>
      <SearchInput
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
          placeholder="Search..."
        />
      <TableComponent columns={columns} data={filteredData} loading={loading} />

      {/* Confirmation Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this request? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteClick} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default RequestCommissionComponent;
