import React, { useState, useEffect } from 'react';
import { TableContainer, Paper, Typography, Button, TextField, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { db } from '../../firebase';
import { collection, addDoc, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import TableComponent from '../../components/TableComponent';
import SearchInput from '../../components/SearchInput';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CustomModal from '../../components/CustomModal';
import { useAuth } from '../../context/AuthContext';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from '../../components/ConfirmationModa';

const ProjectListPage = () => {
  const [projectData, setProjectData] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [open, setOpen] = useState(false);
  const [newProject, setNewProject] = useState({ name: '', commissionPercentage: '', isExcludeSST: false });
  const [searchQuery, setSearchQuery] = useState(""); // Add state for search query
  const { userData, currentUser } = useAuth();
  const [saving, setSaving] = useState(false);
  const [targetProjectId, setTargetProjectId] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
 

  // Function to fetch projects from Firebase and listen for real-time updates
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "projects"), (querySnapshot) => {
      const projects = querySnapshot.docs.map((doc, index) => ({
        id: doc.id,
        no: index + 1,
        ...doc.data(),
      }));
      setProjectData(projects);
      setLoading(false);
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setNewProject({
      ...newProject,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleAddProject = async () => {
    setSaving(true);
    try {
      const newProjectData = {
        name: newProject.name,
        commissionPercentage: parseFloat(newProject.commissionPercentage),
        isExcludeSST: newProject.isExcludeSST,
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: currentUser ? currentUser.uid : 'unknown',
        updatedBy: currentUser ? currentUser.uid : 'unknown',
      };
  
      // Add new project to Firebase
      await addDoc(collection(db, "projects"), newProjectData);
  
      // Close the modal and reset the form
      handleClose();
      setNewProject({ name: '', commissionPercentage: '', isExcludeSST: false });
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setSaving(false);
    }
  };
  

  // Filter projects based on the search query
  const filteredProjects = projectData.filter(
    (project) =>
      project.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

   // Define columns for the TableComponent
   const columns = [
    { id: 'no', label: 'No.', minWidth: 50 },
    { id: 'name', label: 'Name', minWidth: 150 },
    { id: 'commissionPercentage', label: 'Commission Percentage (%)', minWidth: 150 },
    { 
      id: 'isExcludeSST', 
      label: 'Exclude SST', 
      minWidth: 150, 
      render: (row) => (
        row.isExcludeSST ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />
      )
    },
    ...(userData?.position === "Admin" 
      ? [{
          id: 'delete',
          label: 'Action',
          minWidth: 100,
          render: (row) => (
            <IconButton color="error" onClick={() => openConfirmationModal(row.id)}>
              <DeleteIcon />
            </IconButton>
          ),
        }]
      : []),
  ];

  const handleDeleteProject = async () => {
    if (!targetProjectId) return;

    try {
      await deleteDoc(doc(db, "projects", targetProjectId));
      setProjectData((prevData) => prevData.filter((item) => item.id !== targetProjectId));
    } catch (error) {
      console.error("Error deleting project: ", error);
    } finally {
      setConfirmationModalOpen(false);
      setTargetProjectId(null);
    }
  };

  const openConfirmationModal = (projectId) => {
    setTargetProjectId(projectId);
    setConfirmationModalOpen(true);
  };

  const handleCancelConfirmation = () => {
    setConfirmationModalOpen(false);
    setTargetProjectId(null);
  };

  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" gutterBottom sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Project List
        {
          userData.position === 'Admin' && (
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Add Project
            </Button>
          )
        }
      </Typography>
      {/* Search Input */}
      <SearchInput
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search..."
      />

      <TableComponent columns={columns} data={filteredProjects} loading={loading} />

      {/* Add Project Modal */}
      <CustomModal
        open={open}
        onClose={handleClose}
        onSave={handleAddProject}
        title="Add New Project"
        saving={saving}
      >
        <TextField
            autoFocus
            margin="dense"
            label="Project Name"
            name="name"
            fullWidth
            value={newProject.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Commission Percentage"
            name="commissionPercentage"
            type="number"
            fullWidth
            value={newProject.commissionPercentage}
            onChange={handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newProject.isExcludeSST}
                onChange={handleChange}
                name="isExcludeSST"
              />
            }
            label="Exclude SST"
          />
      </CustomModal>

      <ConfirmationModal
        open={confirmationModalOpen}
        onClose={handleCancelConfirmation}
        onConfirm={handleDeleteProject}
        title="Confirm Deletion"
        message="Are you sure you want to delete this project?"
      />
      
    </TableContainer>
  );
};

export default ProjectListPage;
