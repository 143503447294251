import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { query, where, collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase'; // Firestore config
import { formatNumberWithCommas } from '../../../Helper/Helper'; // Helper function for formatting numbers
import { fetchSalesAgentsName } from '../../../services/Services'; // Import the service to fetch agent names

const ViewCommRental = () => {
  const { id } = useParams(); // Get projectDealId from URL params
  const [paymentData, setPaymentData] = useState(null);
  const [agentNames, setAgentNames] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        setLoading(true);

        // Query Firestore for a document where 'projectDealId' field matches the URL parameter
        const q = query(
          collection(db, 'rentalClaimRequest'), // Replace with your collection name
          where('rentalDealId', '==', id)
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Extract the first matching document
          const docSnapshot = querySnapshot.docs[0];
          const data = { id: docSnapshot.id, ...docSnapshot.data() }; // Include document ID with data
          setPaymentData(data);
        } else {
          console.error('No document found with the given rentalDealId.');
        }
      } catch (error) {
        console.error('Error fetching payment data:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchAgents = async () => {
      try {
        const names = await fetchSalesAgentsName(); // Fetch agent names
        setAgentNames(names);
      } catch (error) {
        console.error('Error fetching agent names:', error);
      }
    };

    fetchPaymentData();
    fetchAgents();
  }, [id]);

  const handleDownloadPDF = () => {
    if (!paymentData) return;

    const agentName = agentNames[paymentData.createdBy] || 'N/A'; // Map user ID to name
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text('LSV PROPERTY GROUP SDN BHD', 20, 20);
    doc.text(`PAY TO: ${agentName}`, 20, 40);
    doc.text(`PAYMENT AMOUNT: RM ${formatNumberWithCommas(paymentData.commissionAmount || 0)}`, 20, 60);
    doc.text(`DESCRIPTION: Rental Sales Commission Payment`, 20, 80);
    doc.text(`DATE: ${new Date(paymentData.createdAt).toLocaleDateString() || 'N/A'}`, 20, 100);
    doc.save('payment-voucher.pdf');
  };

  const handleBackButton = () => {
    navigate('/commissions');
  };

  if (loading) {
    return (
      <Box sx={{ padding: 3, textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          Loading payment data...
        </Typography>
      </Box>
    );
  }

  if (!paymentData) {
    return (
      <Box sx={{ padding: 3, textAlign: 'center' }}>
        <Typography variant="h6" color="error">
          No payment data found for the given project deal ID.
        </Typography>
      </Box>
    );
  }

  const agentName = agentNames[paymentData.createdBy] || 'N/A'; // Map user ID to name

  return (
    <Box sx={{ padding: 3 }}>
      <Button
              variant="contained"
              color="primary"
              onClick={handleBackButton}
              sx={{ mb: 2, marginBottom: '50px' }}
            >
              Back
            </Button>
      <Typography variant="h4" gutterBottom>
        Payment Voucher
      </Typography>

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="body1">
          <strong>Pay To:</strong> {agentName}
        </Typography>
        <Typography variant="body1">
          <strong>Amount:</strong> RM {formatNumberWithCommas(paymentData.commissionAmount || 0)}
        </Typography>
        <Typography variant="body1">
          <strong>Description:</strong> Rental Sales Commission Payment
        </Typography>
        <Typography variant="body1">
          <strong>Date:</strong> {new Date(paymentData.createdAt).toLocaleDateString() || 'N/A'}
        </Typography>
      </Box>

      <Button variant="contained" color="primary" onClick={handleDownloadPDF}>
        Download PDF
      </Button>
    </Box>
  );
};

export default ViewCommRental;
