import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, Button } from '@mui/material';
import { collection, getDocs, query, where, doc, getDoc, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { useAuth } from '../../../context/AuthContext';
import TermsConditionComponent from '../../../components/TermsConditionTable';
import ClaimHeaderComponent from '../../../components/ClaimHeaderComponent';
import InfoGridComponent from '../../../components/InfoGridComponent';
import { formatNumberWithCommas } from '../../../Helper/Helper';

const ClaimCommSubsale = () => {
  const { id } = useParams(); // Extract projectClaimRequest ID from the URL
  const [claimRequest, setClaimRequest] = useState(null);
  const [subsaleDeal, setSubsaleDeal] = useState(null);
  const [subsaleClaim, setSubsaleClaim] = useState(null);
  const [agentClaim, setAgentClaim] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const claimRef = useRef(null);
  const { currentUser } = useAuth();
  const [userBankDetails, setUserBankDetails] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch subsaleClaimRequest
        const claimRequestRef = collection(db, 'subsaleDeals');
        const projectDealQuery = query(claimRequestRef, where('subsaleDealId', '==', id));
        const subsaleDealSnapshot = await getDocs(projectDealQuery);
        
        if (!subsaleDealSnapshot.empty) {
          const docData = subsaleDealSnapshot.docs[0];
          const subsaleDealData = { id: docData.id, ...docData.data() };
          setSubsaleDeal(subsaleDealData);
        } else {
          console.error('No subsale deal found for the provided subsaleDealId.');
        }
        
        // Fetch subsale claim
        const subsaleClaimsRef = collection(db, 'subsaleClaims');
        const subsaleClaimQuery = query(subsaleClaimsRef, where('subsaleDealId', '==', id));
        const subsaleClaimSnapshot = await getDocs(subsaleClaimQuery);

        if (!subsaleClaimSnapshot.empty) {
          const subsaleClaimDoc = subsaleClaimSnapshot.docs[0];
          const subsaleClaimData = { id: subsaleClaimDoc.id, ...subsaleClaimDoc.data() };
          setSubsaleClaim(subsaleClaimData);

          // Filter the agent claim based on the current user's UID
          const agentSpecificClaim = subsaleClaimData.agentClaims.find(
            (claim) => claim.agentUid === currentUser.uid
          );

          if (agentSpecificClaim) {
            setAgentClaim(agentSpecificClaim);
          } else {
            console.error('No matching claim found for the current user.');
          }
        } else {
          console.error('No subsale claim found for the provided subsaleDealId.');
        }

        // Fetch user's bank details
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          setUserBankDetails(userDocSnap.data());
        } else {
          console.error('No user data found for the current user.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, currentUser]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  const handleBackButton = () => {
    navigate('/commissions');
  };

  const handleSubmitClaim = async () => {
    try {
      // Create the new claim request object
      const newClaimRequest = {
        createdAt: new Date().toISOString(), // Use ISO string for better sorting and consistency
        updatedAt: new Date().toISOString(),
        createdBy: agentClaim?.agentUid,
        updatedBy: "",
        projectName: subsaleDeal?.projectName,
        purchaserName: subsaleDeal?.purchaserName,
        spaPrice: subsaleDeal?.spaPrice,
        purchasePrice: subsaleDeal?.purchasePrice,
        subsaleClaimId: subsaleDeal?.id, // Include projectClaim document ID
        subsaleDealId: subsaleDeal?.subsaleDealId, // projectDealId = PD-1, etc
        subsaleId: subsaleDeal?.id, // projectDeals document ID
        commissionAmount: agentClaim?.commissionAmount?.toFixed(2),
        status: 9,
      };
  
      // Add new claim request to Firestore and get the document reference
      const subsaleClaimRequestRef = collection(db, 'subsaleClaimRequest');
      const newClaimDocRef = await addDoc(subsaleClaimRequestRef, newClaimRequest);
  
      // Update the claim request to include the document ID
      await updateDoc(newClaimDocRef, { claimId: newClaimDocRef.id });
  
      // Update the projectDeal status to 9
      const subsaleDealRef = doc(db, 'subsaleDeals', subsaleDeal.id);
      await updateDoc(subsaleDealRef, { status: 9 });
  
      alert('Claim submitted successfully!');
      navigate('/commissions');
    } catch (error) {
      console.error('Error submitting claim:', error);
      alert('Failed to submit claim. Please try again.');
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackButton}
        sx={{ mb: 2, marginBottom: '50px' }}
      >
        Back
      </Button>
      <Box ref={claimRef} sx={{ padding: 3, maxWidth: 800, margin: 'auto', border: '1px solid #ddd', borderRadius: 2 }}>

        {/* Header Section */}
        <ClaimHeaderComponent title="SUBSALE CLAIM DETAILS" />

        {/* Claim Request Details */}
        <Box sx={{ padding: 3, marginBottom: 3, border: '1px solid #ddd', borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            Claim Request Details
          </Typography>
          <InfoGridComponent
            items={[
              { title: "Agent Name", data: agentClaim?.agentName },
              { title: "Project Name", data: subsaleDeal?.projectName },
              { title: "Purchaser Name", data: subsaleDeal?.purchaserName },
              { title: "SPA Price", data: `RM${subsaleDeal?.spaPrice}` },
              { title: "Purchased Price", data: `RM${subsaleDeal?.purchasePrice}` },
              { title: "Commission Amount", data: `RM${formatNumberWithCommas(agentClaim?.commissionAmount.toFixed(2))}` },
            ]}
          />
        </Box>
        {/* Full Bank Information */}
        <Box sx={{ marginBottom: 5, padding: 3, border: '1px solid #ddd', borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            Full Bank Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Bank Name:</strong> {userBankDetails?.bankName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Account Number:</strong> {userBankDetails?.bankAccNo}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <Typography variant="body1">
              <strong>Bank Transfer Payable Name:</strong> {userBankDetails?.name}
            </Typography>
          </Grid>
        </Box>

        <TermsConditionComponent />
        {/* Submit Button */}
        {
          subsaleDeal?.status !== 9 && (
            <Box textAlign="right" sx={{ marginTop: 3 }}>
              <Button variant="contained" color="primary" onClick={handleSubmitClaim}>
                Submit Claim
              </Button>
            </Box>
          )
        }
      </Box>
    </div>
  );
};

export default ClaimCommSubsale;
