import React from "react";
import { Dialog, DialogTitle, DialogContent,DialogActions,DialogContentText, Button } from "@mui/material";

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  title = "Confirm Action",
  message = "Are you sure you want to proceed?",
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
       <DialogTitle>{title}</DialogTitle>
       <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={onConfirm}>
            Confirm
          </Button>
        </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
