import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography, Button, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import TableComponent from '../../components/TableComponent'; // Your reusable table component
import VisibilityIcon from '@mui/icons-material/Visibility'; // View icon
import EditIcon from '@mui/icons-material/Edit'; // Edit icon
import DeleteIcon from '@mui/icons-material/Delete'; // Delete icon
import { useNavigate } from 'react-router-dom';
import { collection, deleteDoc, doc, onSnapshot, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Your firebase config file
import { useAuth } from '../../context/AuthContext';
import { StatusWrapper } from '../../components/StatusWrapper';

const Subsale = () => {
  const navigate = useNavigate();
  const { currentUser, userData } = useAuth(); 
  const [subsaleData, setSubsaleData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For filtered search results
  const [searchQuery, setSearchQuery] = useState(''); // For search input
  const [loading, setLoading] = useState(true); // Loading state
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    let unsubscribe;

    const fetchData = async () => {
      setLoading(true);

      try {
        // Fetch statuses for status mapping
        const statusSnapshot = await getDocs(collection(db, 'status'));
        const statusMap = statusSnapshot.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data().Name; // Map status ID to readable name
          return acc;
        }, {});

        // Set up real-time listener for subsaleDeals
        unsubscribe = onSnapshot(collection(db, 'subsaleDeals'), (snapshot) => {
          const subsales = snapshot.docs.map((docSnap, index) => {
            const data = docSnap.data();
            return {
              no: index + 1,
              id: docSnap.id,
              subsaleDealId: data.subsaleDealId,
              purchaserName: data.purchaserName,
              project: data.projectName,
              lotNumber: data.lotNumber,
              status: data.status,
              status1: statusMap[data.status] || 'Unknown Status', // Map status ID to readable name
              ...data,
            };
          });

          if (userData.position === 'Admin') {
            setSubsaleData(subsales);
            setFilteredData(subsales); // Set filtered data to initial data
          } else {
            const userProjects = subsales
              .filter((subsale) => subsale.createdBy === currentUser.uid)
              .sort((a, b) => a.subsaleDealId.localeCompare(b.subsaleDealId)); // Sort by subsaleDealId
            setSubsaleData(userProjects);
            setFilteredData(userProjects); // Set filtered data to user-specific data
          }

          setLoading(false);
        });
      } catch (error) {
        console.error('Error fetching subsale data:', error);
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [currentUser, userData.position]);

  // Handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = subsaleData.filter(
      (item) =>
        item.subsaleDealId.toLowerCase().includes(query) ||
        item.purchaserName.toLowerCase().includes(query) ||
        item.project.toLowerCase().includes(query)
    );
    setFilteredData(filtered);
  };

  // Define columns for the table
  const columns = [
    { id: 'no', label: 'No.', minWidth: 50 },
    { id: 'subsaleDealId', label: 'Subsales Order ID', minWidth: 150 },
    { id: 'purchaserName', label: 'Purchaser Name', minWidth: 150 },
    { id: 'project', label: 'Project Name', minWidth: 150 },
    { id: 'lotNumber', label: 'Lot Number', minWidth: 150 },
    { id: 'purchasePrice', label: 'Purchase Price', minWidth: 150 },
    {
      id: 'status1',
      label: 'Status',
      minWidth: 150,
      render: (row) => StatusWrapper(row.status, row.status1),
    },
    {
      id: 'action',
      label: 'Action',
      minWidth: 150,
      render: (row) => (
        <Box>
          <IconButton color="primary" onClick={() => handleView(row)}>
            <VisibilityIcon />
          </IconButton>
          {row.status !== 3 && row.status !== 4 && row.status !== 10 && row.status !== 8 && row.status !== 7 && (
              <IconButton color="black" onClick={() => handleEdit(row)}>
                <EditIcon />
              </IconButton>
          )}
          {row.status !== 3 && row.status !== 4 && row.status !== 10 && row.status !== 8 && row.status !== 7 && (
              <IconButton color="error" onClick={() => handleDeleteClick(row)}>
                <DeleteIcon />
              </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const handleView = (row) => {
    navigate(`/sales/view-subsale-deal/${row.id}`);
  };

  const handleEdit = (row) => {
    navigate(`/sales/edit-subsale-deal/${row.id}`);
  };

  const handleDelete = async () => {
    if (selectedRow) {
      try {
        await deleteDoc(doc(db, 'subsaleDeals', selectedRow.id));
        setOpen(false);
        setSelectedRow(null);
      } catch (error) {
        console.error('Error deleting document:', error);
      }
    }
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleAddNewDeal = () => {
    navigate('/sales/create-subsale-deal');
  };

  return (
    <Paper>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          padding: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        Subsale Deal List
        <Button variant="contained" color="primary" onClick={handleAddNewDeal}>
          Add New
        </Button>
      </Typography>

      <TextField
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearchChange}
        sx={{
          width: '250px',
          padding: '10px',
          marginBottom: '10px',
          '.MuiInputBase-root': {
            height: '40px',
          },
        }}
      />

      <TableComponent columns={columns} data={filteredData} loading={loading} />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this subsale deal? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Subsale;
