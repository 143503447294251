import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Button, Paper } from '@mui/material';
import TableComponent from '../../components/TableComponent'; 
import VisibilityIcon from '@mui/icons-material/Visibility'; 
import EditIcon from '@mui/icons-material/Edit'; 
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase'; 
import { useAuth } from '../../context/AuthContext';
import { StatusWrapper } from '../../components/StatusWrapper';
import ConfirmationModal from '../../components/ConfirmationModa';
import SearchInput from '../../components/SearchInput';

const Project = () => {
  const navigate = useNavigate();
  const { currentUser, userData } = useAuth();
  const [projectData, setProjectData] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); 
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    let unsubscribe; 

    const fetchData = async () => {
      setLoading(true);

      try {
        // 1) Fetch all projects for name lookup (once)
        const projectSnapshot = await getDocs(collection(db, 'projects'));
        const projectMap = projectSnapshot.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data().name; 
          return acc;
        }, {});

        // 2) Fetch all statuses for status lookup (once)
        const statusSnapshot = await getDocs(collection(db, 'status'));
        const statusMap = statusSnapshot.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data().Name; 
          return acc;
        }, {});

        // 3) Listen to projectDeals in real time using onSnapshot
        unsubscribe = onSnapshot(collection(db, 'projectDeals'), (snapshot) => {
          const projects = snapshot.docs.map((docSnap, index) => {
            const data = docSnap.data();
            return {
              no: index + 1,
              id: docSnap.id,
              projectDealId: data.projectDealId,
              purchaserName: data.purchaserName,
              projectId: data.projectId,
              project: projectMap[data.projectId] || 'Unknown Project',
              lotNumber: data.lotNumber,
              status: data.status,
              status1: statusMap[data.status] || 'Unknown Status',
              bookingDate: data.bookingDate,
              ...data,
            };
          });

          if (userData.position === 'Admin') {
            setProjectData(projects);
          } else {
            const userProjects = projects
              .filter((project) => project.createdBy === currentUser.uid)
              .sort((a, b) => a.projectDealId.localeCompare(b.projectDealId));
            setProjectData(userProjects);
          }

          setLoading(false);
        });
      } catch (error) {
        console.error('Error fetching project data:', error);
        setLoading(false);
      }
    };

    fetchData();

    // Cleanup the onSnapshot listener on unmount
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [currentUser, userData.position]);

  // Filtered data based on search query
  const filteredData = projectData.filter((project) =>
    Object.values(project)
      .join(' ')
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );


  const handleView = (row) => {
    navigate(`/sales/view-project-deal/${row.id}`);
  };

  const handleEdit = (row) => {
    navigate(`/sales/edit-project-deal/${row.id}`);
  };

  const handleDelete = async () => {
    if (selectedRow) {
      try {
        // Delete the document from Firestore
        await deleteDoc(doc(db, 'projectDeals', selectedRow.id));
        // Update the local state to remove the deleted item
        setProjectData((prevData) => prevData.filter((item) => item.id !== selectedRow.id));
        setOpen(false); // Close the confirmation dialog
        setSelectedRow(null); // Clear the selected row
      } catch (error) {
        console.error('Error deleting document:', error);
      }
    }
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpen(true); // Open the confirmation dialog
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleAddNewDeal = () => {
    navigate('/sales/create-project-deal');
  };

  // Define columns for the table
  const columns = [
    { id: 'no', label: 'No.', minWidth: 50 },
    { id: 'projectDealId', label: 'Project Order ID', minWidth: 150 },
    { id: 'lotNumber', label: 'Lot No.', minWidth: 150 },
    { id: 'purchaserName', label: 'Purchaser', minWidth: 150 },
    { id: 'project', label: 'Project', minWidth: 150 },
    {
      id: 'status1',
      label: 'Status',
      minWidth: 150,
      render: (row) => StatusWrapper(row.status, row.status1),
    },
    { id: 'bookingDate', label: 'Booking Date', minWidth: 150 },
    {
      id: 'action',
      label: 'Action',
      minWidth: 150,
      render: (row) => (
        <Box>
          <IconButton color="primary" onClick={() => handleView(row)}>
            <VisibilityIcon />
          </IconButton>
          {/* Conditionally render the edit button */}
          {row.status !== 3 && row.status !== 4 && row.status !== 10 && row.status !== 8 && row.status !== 7 && (
              <IconButton color="black" onClick={() => handleEdit(row)}>
                <EditIcon />
              </IconButton>
          )}
          {row.status !== 3 && row.status !== 4 && row.status !== 10 && row.status !== 8 && row.status !== 7 && (
              <IconButton color="error" onClick={() => handleDeleteClick(row)}>
                <DeleteIcon />
              </IconButton>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Paper>
      <Typography variant="h6" gutterBottom sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Project Deal List
        <Button variant="contained" color="primary" onClick={handleAddNewDeal}>
          Add New
        </Button>
      </Typography>

      {/* Search Input */}
      <SearchInput
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search..."
        sx={{
          width: '250px',
          padding: '10px',
          marginBottom: '10px',
          '.MuiInputBase-root': {
            height: '40px',
          },
        }}
      />

      <TableComponent columns={columns} data={filteredData} loading={loading} />

      {/* Confirmation Dialog */}
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        onConfirm={handleDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete this rental deal? This action cannot be undone."
      />
    </Paper>
  );
};

export default Project;
