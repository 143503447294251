import { Chip } from '@mui/material';

export const StatusWrapper = (status, status1) => {
  const statusMap = {
    1: { color: 'gray' },
    2: { color: 'orange' },
    3: { color: 'yellow' },
    4: { color: 'green' },
    5: { color: 'blue' },
    6: { color: 'red' },
    7: { color: 'green' },
    8: { color: 'green' },
    9: { color: 'yellow' },
    10: { color: 'green' },
    11: { color: 'green' },
  };

  const statusInfo = statusMap[status] || { text: 'Unknown', color: 'gray' };

  return (
    <Chip
      label={status1}
      sx={{
        backgroundColor: statusInfo.color,
        color: statusInfo.color === 'yellow' ? 'black' : 'white', // Ensure readability
        fontWeight: 'bold',
        textTransform: 'capitalize',
      }}
    />
  );
};
