import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, Button } from '@mui/material';
import { collection, getDocs, query, where, doc, getDoc, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { useAuth } from '../../../context/AuthContext';
import TermsConditionComponent from '../../../components/TermsConditionTable';
import ClaimHeaderComponent from '../../../components/ClaimHeaderComponent';
import InfoGridComponent from '../../../components/InfoGridComponent';
import { fetchProjectDetails } from '../../../services/Services';
import { formatNumberWithCommas } from '../../../Helper/Helper';

const ClaimCommProject = () => {
  const { id } = useParams(); // Extract projectDealId from the URL
  const { currentUser } = useAuth();
  const [projectDeal, setProjectDeal] = useState(null);
  const [projectClaim, setProjectClaim] = useState(null);
  const [agentClaim, setAgentClaim] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userBankDetails, setUserBankDetails] = useState(null);
  const navigate = useNavigate();
  const claimRef = useRef(null);
  const [projectName, setProjectName] = useState("N/A");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch project deal
        const projectDealsRef = collection(db, 'projectDeals');
        const projectDealQuery = query(projectDealsRef, where('projectDealId', '==', id));
        const projectDealSnapshot = await getDocs(projectDealQuery);

        if (!projectDealSnapshot.empty) {
          const docData = projectDealSnapshot.docs[0];
          const projectDealData = { id: docData.id, ...docData.data() };
          setProjectDeal(projectDealData);
        } else {
          console.error('No project deal found for the provided projectDealId.');
        }

        // Fetch project claim
        const projectClaimsRef = collection(db, 'projectClaims');
        const projectClaimQuery = query(projectClaimsRef, where('projectDealId', '==', id));
        const projectClaimSnapshot = await getDocs(projectClaimQuery);

        if (!projectClaimSnapshot.empty) {
          const projectClaimDoc = projectClaimSnapshot.docs[0];
          const projectClaimData = { id: projectClaimDoc.id, ...projectClaimDoc.data() };
          setProjectClaim(projectClaimData);

          // Filter the agent claim based on the current user's UID
          const agentSpecificClaim = projectClaimData.agentClaims.find(
            (claim) => claim.agentUid === currentUser.uid
          );

          if (agentSpecificClaim) {
            setAgentClaim(agentSpecificClaim);
          } else {
            console.error('No matching claim found for the current user.');
          }
        } else {
          console.error('No project claim found for the provided projectDealId.');
        }

        // Fetch user's bank details
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          setUserBankDetails(userDocSnap.data());
        } else {
          console.error('No user data found for the current user.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, currentUser]);

  // Fetch project name when projectDeal is updated
  useEffect(() => {
    const fetchProjectName = async () => {
      if (projectDeal?.projectId) {
        try {
          const projectDetails = await fetchProjectDetails(projectDeal.projectId);
          if (projectDetails) {
            setProjectName(projectDetails.name);
          }
        } catch (error) {
          console.error('Error fetching project name:', error);
        }
      }
    };

    fetchProjectName();
  }, [projectDeal]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  const handleBackButton = () => {
    navigate('/commissions');
  };

  const handleSubmitClaim = async () => {
    try {
      // Create the new claim request object
      const newClaimRequest = {
        createdAt: new Date().toISOString(), // Use ISO string for better sorting and consistency
        updatedAt: new Date().toISOString(),
        createdBy: agentClaim?.agentUid,
        updatedBy: "",
        projectName: projectName,
        purchaserName: projectDeal?.purchaserName,
        spaPrice: projectDeal?.spaPrice,
        nettPrice: projectDeal?.nettPrice,
        projectClaimId: projectClaim?.id, // Include projectClaim document ID
        projectDealId: projectDeal?.projectDealId, // projectDealId = PD-1, etc
        projectId: projectDeal?.id, // projectDeals document ID
        commissionAmount: agentClaim?.commissionAmount?.toFixed(2),
        status: 9,
      };
  
      // Add new claim request to Firestore and get the document reference
      const projectClaimRequestRef = collection(db, 'projectClaimRequest');
      const newClaimDocRef = await addDoc(projectClaimRequestRef, newClaimRequest);
  
      // Update the claim request to include the document ID
      await updateDoc(newClaimDocRef, { claimId: newClaimDocRef.id });
  
      // Update the projectDeal status to 9
      const projectDealRef = doc(db, 'projectDeals', projectDeal.id);
      await updateDoc(projectDealRef, { status: 9 });
  
      alert('Claim submitted successfully!');
      navigate('/commissions');
    } catch (error) {
      console.error('Error submitting claim:', error);
      alert('Failed to submit claim. Please try again.');
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackButton}
        sx={{ mb: 2, marginBottom: '50px' }}
      >
        Back
      </Button>
      <Box ref={claimRef} sx={{ padding: 3, maxWidth: 800, margin: 'auto', border: '1px solid #ddd', borderRadius: 2 }}>

        {/* Header Section */}
        <ClaimHeaderComponent title="PROJECT CLAIM FORM"/>

        {/* Agent Claim Details */}
        <Box sx={{ padding: 3, marginBottom: 3, border: '1px solid #ddd', borderRadius: 2 }}>
          <InfoGridComponent items={
            [
              {title: "Agent Name", data: agentClaim?.agentName },
              {title: "Date", data: new Date().toLocaleDateString() },
              {title: "Project Name", data: projectName },
              {title: "Purchaser Name", data: projectDeal?.purchaserName },
              {title: "SPA Price", data: `RM${projectDeal?.spaPrice}` },
              {title: "Nett Price", data: `RM${projectDeal?.nettPrice}` },
              {title: "Project Deal ID", data: projectDeal?.projectDealId },
              {title: "Commission Amount", data: `RM${formatNumberWithCommas(agentClaim?.commissionAmount?.toFixed(2))}`}
            ]
          } />
        </Box>

        <Box sx={{ marginBottom: 5, padding: 3, border: '1px solid #ddd', borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            Full Bank Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Bank Name:</strong> {userBankDetails?.bankName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Account Number:</strong> {userBankDetails?.bankAccNo}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <Typography variant="body1">
              <strong>Bank Transfer Payable Name:</strong> {userBankDetails?.name}
            </Typography>
          </Grid>
        </Box>

        <TermsConditionComponent />

        {/* Submit Button */}
        {
          projectDeal?.status !== 9 && (
            <Box textAlign="right" sx={{ marginTop: 3 }}>
              <Button variant="contained" color="primary" onClick={handleSubmitClaim}>
                Submit Claim
              </Button>
            </Box>
          )
        }
      </Box>
    </div>
  );
};

export default ClaimCommProject;
