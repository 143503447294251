import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";

const CustomModal = ({
  open,
  onClose,
  onSave,
  title,
  children,
  saving,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "800px", // Set maximum width to avoid excessive stretching
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          variant="h6"
          id="custom-modal-title"
          sx={{ mb: 2 }}
        >
          {title}
        </Typography>
        <Box id="custom-modal-description" sx={{ mb: 2 }}>
          {children}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            disabled={saving}
          >
            {saving ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
