import React from "react";
import { TextField } from "@mui/material";

const SearchInput = ({ value, onChange, placeholder = "Search...", sx = {} }) => {
  return (
    <TextField
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      sx={{
        padding: "10px", // Adjust padding around the TextField
        ".MuiInputBase-root": {
          height: "40px", // Set a shorter height for the input field
        },
        ...sx, // Merge additional styles passed via props
      }}
    />
  );
};

export default SearchInput;
