import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, IconButton } from '@mui/material';
import TableComponent from '../../components/TableComponent';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../context/AuthContext';
import { StatusWrapper } from '../../components/StatusWrapper';
import { formatNumberWithCommas } from '../../Helper/Helper';
import withdrawIcon from '../../assets/withdraw.png';
import { DownloadRounded } from '@mui/icons-material';

const CommissionsPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [commissionData, setCommissionData] = useState([]);
  const [statusMap, setStatusMap] = useState({});
  const [loading, setLoading] = useState(true);

  // Route mapping for view buttons
  const routeMapping = {
    projectClaims: '/commissions/view-project-commission',
    rentalClaims: '/commissions/view-rental-commission',
    subsaleClaims: '/commissions/view-subsale-commission',
  };
  
  const claimRouteMapping = {
    projectClaims: '/commissions/claim-project-commission',
    rentalClaims: '/commissions/claim-rental-commission',
    subsaleClaims: '/commissions/claim-subsale-commission',
  }

  useEffect(() => {
    const fetchClaimsData = async () => {
      setLoading(true);
  
      try {
        const collections = ['projectClaims', 'rentalClaims', 'subsaleClaims'];
  
        let allClaimsData = [];
        const statusSnapshot = await getDocs(collection(db, 'status'));
        const statuses = {};
        statusSnapshot.forEach((doc) => {
          statuses[doc.id] = doc.data().Name; // Map status ID to name
        });
        setStatusMap(statuses);
  
        const promises = collections.map(async (coll) => {
          const collRef = collection(db, coll);
          const querySnapshot = await getDocs(collRef);
  
          const collectionData = [];
          querySnapshot.forEach((doc) => {
            const claimData = doc.data();
            claimData.agentClaims.forEach((agentClaim, index) => {
              if (agentClaim.agentUid === currentUser.uid) {
                collectionData.push({
                  id: `${doc.id}_${index}`,
                  no: index + 1,
                  collection: coll,
                  type: coll === 'projectClaims' ? 'Project' : coll === 'rentalClaims' ? 'Rental' : 'Subsale',
                  dealId: claimData.projectDealId || claimData.rentalDealId || claimData.subsaleDealId,
                  agentName: agentClaim.agentName || 'Unknown Agent',
                  commissionAmount: agentClaim.commissionAmount,
                  status: agentClaim.isClaimed ? 8 : 2, // Use isClaimed to determine status
                  status1: statuses[agentClaim.isClaimed ? '8' : '2'] || 'Unknown', // Map status ID to name
                  createdAt: claimData.createdAt
                    ? new Date(claimData.createdAt)
                    : null,
                  agentUid: agentClaim.agentUid,
                });
              }
            });
          });
  
          return collectionData;
        });
  
        const results = await Promise.all(promises);
        allClaimsData = results.flat(); // Flatten the results array
  
        // Sort data by `createdAt` in descending order (latest first)
        allClaimsData.sort((a, b) => b.createdAt - a.createdAt);
  
        setCommissionData(allClaimsData);
      } catch (error) {
        console.error('Error fetching claims data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchClaimsData();
  }, [currentUser]);
  

  const handleView = (row) => {
    const route = `${routeMapping[row.collection]}/${row.dealId}`;
    navigate(route, { state: { paymentData: row } });
  };

  const handleClaim = (row) => {
    const route = `${claimRouteMapping[row.collection]}/${row.dealId}`;
    navigate(route);
    // navigate(route, { state: { paymentData: row } });
  };

  const columns = [
    { id: 'no', label: 'No.', minWidth: 50 },
    { id: 'type', label: 'Type', minWidth: 150 },
    { id: 'dealId', label: 'Deal ID', minWidth: 150 },
    { id: 'agentName', label: 'Agent Name', minWidth: 150 },
    {
      id: 'commissionAmount',
      label: 'Commission (RM)',
      minWidth: 150,
      render: (row) => formatNumberWithCommas(row.commissionAmount.toFixed(2)),
    },
    {
      id: 'status',
      label: 'Claim Status',
      minWidth: 150,
      render: (row) => StatusWrapper(row.status, row.status1), // Use StatusWrapper here
    },
    {
      id: 'createdAt',
      label: 'Created At',
      minWidth: 150,
      render: (row) => row.createdAt ? row.createdAt.toLocaleDateString() : 'N/A', // Properly format the date
    },
    {
      id: 'action',
      label: 'Action',
      minWidth: 100,
      render: (row) => (
        <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 1 }}>
          {row.status === 2 && (
            <IconButton color="primary" onClick={() => handleClaim(row)}>
              <img
                src={withdrawIcon}
                alt="Withdraw"
                style={{ width: 24, height: 24 }} // Adjust size if needed
              />
            </IconButton>
          )}
          {row.status === 8 && (
            <IconButton color="primary" onClick={() => handleView(row)}>
              <DownloadRounded />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Paper>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        Commission Claims
      </Typography>
      <TableComponent columns={columns} data={commissionData} loading={loading} />
    </Paper>
  );
};

export default CommissionsPage;
