import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, Avatar, TextField, CardContent, Card, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import { db } from '../../../firebase'; // Your Firebase configuration
import { formatNumberWithCommas } from '../../../Helper/Helper';
import { renderDocumentField } from '../../../components/RenderDocumentField';
import StickyNote from '../../../components/StickyNotes';

const ViewSubsaleDealPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [subsaleDeal, setSubsaleDeal] = useState(null);
  const [agentNames, setAgentNames] = useState({});

  useEffect(() => {
    const fetchSubsaleDeal = async () => {
      try {
        const docRef = doc(db, 'subsaleDeals', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setSubsaleDeal(docSnap.data());
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching subsale deal:', error);
      }
    };

    const fetchAgentNames = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const querySnapshot = await getDocs(usersCollection);
        const names = {};
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          names[doc.id] = data.name;
        });
        setAgentNames(names);
      } catch (error) {
        console.error('Error fetching agent names:', error);
      }
    };

    fetchSubsaleDeal();
    fetchAgentNames();
  }, [id]);

  if (!subsaleDeal) {
    return <Typography>Loading...</Typography>;
  }

  const handleBackButton = () => {
    navigate('/sales', { state: { tabIndex: 1 } });
  };

  return (
    <Box sx={{ p: 3, border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#fff', position: 'relative' }}>
      <StickyNote status={subsaleDeal.status} comments={subsaleDeal.comments} />

      <Button
        variant="contained"
        color="primary"
        onClick={handleBackButton}
        sx={{ mb: 2, marginBottom: '50px' }}
      >
        Back
      </Button>
      <Typography variant="h6" gutterBottom sx={{ mb: 2, marginBottom: '20px' }}>
        Subsale Deal Information
      </Typography>
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Landlord Name"
            variant="outlined"
            fullWidth
            value={subsaleDeal.landlordName || 'N/A'}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Purchaser Name"
            variant="outlined"
            fullWidth
            value={subsaleDeal.purchaserName || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Project Name"
            variant="outlined"
            fullWidth
            value={subsaleDeal.projectName || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Lot Number"
            variant="outlined"
            fullWidth
            value={subsaleDeal.lotNumber || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </Grid>

        {/* Middle Column */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Booking Amount (RM)"
            variant="outlined"
            fullWidth
            value={formatNumberWithCommas(subsaleDeal.bookingAmount || 0)}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Purchase Price (RM)"
            variant="outlined"
            fullWidth
            value={formatNumberWithCommas(subsaleDeal.purchasePrice || 0)}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="SPA Price (RM)"
            variant="outlined"
            fullWidth
            value={formatNumberWithCommas(subsaleDeal.spaPrice || 0)}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Commission Percentage (%)"
            variant="outlined"
            fullWidth
            value={subsaleDeal.commissionPercentage || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Commission Amount (RM)"
            variant="outlined"
            fullWidth
            value={formatNumberWithCommas(subsaleDeal.commissionAmount || 0)}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label={subsaleDeal.rebate.type === '%' ? 'Rebate (%)' : 'Rebate (RM)'}
            variant="outlined"
            fullWidth
            value={subsaleDeal.rebate.value || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} sm={4}>
          {renderDocumentField("Booking Form", subsaleDeal.bookingFormUrl)}
          {renderDocumentField("SPA Copy", subsaleDeal.spaCopyUrl)}
          {renderDocumentField("Cancellation Form", subsaleDeal.cancellationFormUrl)}
        </Grid>
      </Grid>

      {/* Agents Information */}
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Agents Information
          </Typography>
          {subsaleDeal.salesAgentShare.map((agent, index) => (
            <Box key={index} display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Avatar sx={{ bgcolor: '#3f51b5', mr: 2 }}>
                {agentNames[agent.uid]?.[0] || 'A'}
              </Avatar>
              <Box>
                <Typography>
                  Sales Agent: {agentNames[agent.uid] || agent.uid}
                </Typography>
                <Typography>
                  Commission Share: {agent.sharePercentage}%
                </Typography>
              </Box>
            </Box>
          ))}
          <TextField
            label="Remarks"
            variant="outlined"
            fullWidth
            value={subsaleDeal.remark || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ViewSubsaleDealPage;
