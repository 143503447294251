import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, query, collection, getDocs, where, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import ClaimHeaderComponent from '../../../components/ClaimHeaderComponent';
import TermsConditionComponent from '../../../components/TermsConditionTable';
import { fetchSalesAgentsName, fetchEmailTemplates } from '../../../services/Services';
import { sendEmail } from '../../../Helper/EmailNotification';

const RentalClaimDetails = () => {
    const { id } = useParams(); // ID from the rentalClaimRequest document
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [rentalClaimRequest, setRentalClaimRequest] = useState(null);
    const [rentalDeal, setRentalDeal] = useState(null);
    const [rentalClaims, setRentalClaims] = useState([]);
    const [agentDetails, setAgentDetails] = useState(null);
    const [recruiterDetails, setRecruiterDetails] = useState(null);
    const [commissionAmount, setCommissionAmount] = useState(null);
    const [otherAgents, setOtherAgents] = useState([]); // For co-broke agents
    const [stampingFeesAgentName, setStampingFeesAgentName] = useState('N/A'); 
  
    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        try {
          // Fetch rentalClaimRequest document
          const rentalClaimRequestDocRef = doc(db, 'rentalClaimRequest', id);
          const rentalClaimRequestSnapshot = await getDoc(rentalClaimRequestDocRef);
  
          if (rentalClaimRequestSnapshot.exists()) {
            const rentalClaimRequestData = rentalClaimRequestSnapshot.data();
            setRentalClaimRequest(rentalClaimRequestData);
  
            const rentalDealsId = rentalClaimRequestData.rentalDealsId;
  
            // Fetch rentalDeals document
            const rentalDealDocRef = doc(db, 'rentalDeals', rentalDealsId);
            const rentalDealSnapshot = await getDoc(rentalDealDocRef);
  
            if (rentalDealSnapshot.exists()) {
              setRentalDeal(rentalDealSnapshot.data());
            } else {
              console.error('Rental Deal not found');
            }
  
            // Fetch rentalClaims document
            const rentalClaimsDocRef = doc(db, 'rentalClaims', rentalDealsId);
            const rentalClaimsSnap = await getDoc(rentalClaimsDocRef);
  
            if (rentalClaimsSnap.exists()) {
              const rentalClaimsData = rentalClaimsSnap.data();
  
              // Find the agent's commissionAmount based on createdBy
              const agentClaim = rentalClaimsData.agentClaims.find(
                (claim) => claim.agentUid === rentalClaimRequestData.createdBy
              );
  
              if (agentClaim) {
                setCommissionAmount(agentClaim.commissionAmount);
  
                // Find other agents for co-broke information
                const otherAgentsList = rentalClaimsData.agentClaims.filter(
                  (claim) => claim.agentUid !== rentalClaimRequestData.createdBy
                );
                setOtherAgents(otherAgentsList);
              } else {
                console.error('No matching agent claim found.');
              }
            } else {
              console.error('Rental claims document not found.');
            }
  
            // Fetch agent details using createdBy from rentalClaimRequest
            const agentDocRef = doc(db, 'users', rentalClaimRequestData.createdBy);
            const agentSnapshot = await getDoc(agentDocRef);
            if (agentSnapshot.exists()) {
              const agentData = agentSnapshot.data();
              setAgentDetails(agentData);
  
              // Fetch recruiter details using recruiterId from agentData
              if (agentData.recruiterId) {
                const recruiterDocRef = doc(db, 'users', agentData.recruiterId);
                const recruiterSnapshot = await getDoc(recruiterDocRef);
                if (recruiterSnapshot.exists()) {
                  setRecruiterDetails(recruiterSnapshot.data());
                } else {
                  console.error('Recruiter not found');
                }
              }
            } else {
              console.error('Agent not found');
            }
  
            // Fetch the name of the agent for stamping fees
            if (rentalClaimRequestData.stampingFeesTo) {
              const agentNames = await fetchSalesAgentsName();
              setStampingFeesAgentName(agentNames[rentalClaimRequestData.sstPayableTo] || 'N/A');
            }
          } else {
            console.error('Rental Claim Request not found');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, [id]);
  
    const handleApproveClaim = async () => {
      try {
        if (rentalClaimRequest?.rentalDealsId) {
          // Reference to the rentalDeals document
          const rentalDealDocRef = doc(db, 'rentalDeals', rentalClaimRequest.rentalDealsId);
    
          // Reference to the rentalClaimRequest document
          const rentalClaimRequestDocRef = doc(db, 'rentalClaimRequest', id);
    
          // Update the status to 8 in both rentalDeals and rentalClaimRequest
          await Promise.all([
            updateDoc(rentalDealDocRef, {
              status: 8,
              updatedAt: new Date().toISOString(),
            }),
            updateDoc(rentalClaimRequestDocRef, {
              status: 8,
              updatedAt: new Date().toISOString(),
            }),
          ]);


    
          // Reference to the rentalClaims document
          const rentalClaimsDocRef = doc(db, 'rentalClaims', rentalClaimRequest.rentalDealsId);
          const rentalClaimsSnap = await getDoc(rentalClaimsDocRef);
    
          if (rentalClaimsSnap.exists()) {
            const rentalClaimsData = rentalClaimsSnap.data();
    
            // Update the isClaimed property for the matching agentUid
            const updatedAgentClaims = rentalClaimsData.agentClaims.map((claim) => {
              if (claim.agentUid === rentalClaimRequest.createdBy) {
                return { ...claim, isClaimed: true, updatedAt: new Date().toISOString() };
              }
              return claim;
            });
    
            // Update the rentalClaims document with the updated agentClaims
            await updateDoc(rentalClaimsDocRef, { agentClaims: updatedAgentClaims });
    
            // Fetch email templates
            const emailTemplates = await fetchEmailTemplates();
        
            if (emailTemplates?.Claims_Approved) {
              const emailTemplate = emailTemplates.Claims_Approved;
              const personalizedMessage = emailTemplate.body
                .replace('{{dealId}}', rentalClaimRequest.rentalDealId || 'N/A')
                .replace('{{commission}}', `${commissionAmount || '0.00'}`);
        
              // Send email to the agent
              const agent = agentDetails;
              if (agent?.email) {
                const userDetails = {
                  name: agent.name,
                  email: agent.email, // Ensure email is fetched in the fetchSalesAgents service
                };
                await sendEmail(userDetails, personalizedMessage, emailTemplate.subject);
              }
            }
            alert('Claim approved successfully!');
            navigate('/requests', { state: { tabIndex: 1 } }); // Redirect to the requests page
          } else {
            console.error('Rental claims document not found');
            alert('Failed to find rental claims document.');
          }
        } else {
          console.error('Rental Deals ID not found in rentalClaimRequest');
          alert('Rental Deals ID not found.');
        }
      } catch (error) {
        console.error('Error approving claim:', error);
        alert('Failed to approve claim. Please try again.');
      }
    };  
      

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  const handleBackButton = () => {
    navigate('/requests', { state: { tabIndex: 1 } })
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackButton}
        sx={{ mb: 2, marginBottom: '50px' }}
      >
        Back
      </Button>
      <Box sx={{ padding: 3, maxWidth: 800, margin: 'auto', border: '1px solid #ddd', borderRadius: 2 }}>
      
      <ClaimHeaderComponent title="RENTAL CLAIM FORM"/>

        {/* Agent Claim Details */}
        <Box sx={{ padding: 3, marginBottom: 3, border: '1px solid #ddd', borderRadius: 2 }}>
          {/* Agent Details */}
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Agent Name:</strong> {agentDetails?.name || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Date:</strong> {rentalClaimRequest?.createdAt ? new Date(rentalClaimRequest.createdAt).toLocaleDateString() : 'N/A'}
              </Typography>
            </Grid>
          </Grid>

          {/* Landlord and Tenant Information */}
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Landlord Name:</strong> {rentalDeal?.landlordName || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Tenant Name:</strong> {rentalDeal?.tenantName || 'N/A'}
              </Typography>
            </Grid>
          </Grid>

          {/* Project and Lot Information */}
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Project Name:</strong> {rentalDeal?.projectName || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Lot/Unit Number:</strong> {rentalDeal?.lotNumber || 'N/A'}
              </Typography>
            </Grid>
          </Grid>

          {/* Financial Details */}
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Rental Per Month:</strong> RM{rentalDeal?.rentalPerMonth || 0}.00
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Booking Fees LSV Received:</strong> RM{rentalDeal?.bookingFees || 0}.00
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Stamping Fees:</strong> RM{rentalDeal?.stampingFees || 0}.00
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>6% SST:</strong> RM{rentalDeal?.sst || 0}.00
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Rental Tenure:</strong> {rentalDeal?.yearsOfTenure || 'N/A'} year(s)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Commission:</strong> 0.5 Month
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Security Deposit:</strong> RM{rentalDeal?.securityDeposit || 0}.00
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Utility Deposit:</strong> RM{rentalDeal?.utilityDeposit || 0}.00
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Access Card Deposit:</strong> RM{rentalDeal?.accessCardDeposit || 0}.00
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* Co-Broke and Stamping Fees */}
              <Typography variant="body1" sx={{ marginBottom: 2 }}>
                <strong>Co-Broke Agents:</strong>{' '}
                {otherAgents.length > 0
                  ? otherAgents.map((agent, index) => (
                      <span key={index}>
                        {agent.agentName}
                        {index < otherAgents.length - 1 ? ', ' : ''}
                      </span>
                    ))
                  : 'N/A'}
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>SST payable to which agent if co-broke:</strong> {stampingFeesAgentName || 'N/A'}
          </Typography>

          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>Total Rental Commission to be paid to agent (based on your tier):</strong> RM{commissionAmount || 0}.00
          </Typography>
        </Box>

        <Box sx={{ marginBottom: 5, padding: 3, border: '1px solid #ddd', borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            Full Bank Information
          </Typography>
          <Box sx={{ marginBottom: 3 }}>
            {/* Agent Section */}
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
              1. Agent
            </Typography>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Bank Name:</strong> {agentDetails?.bankName || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Account Number:</strong> {agentDetails?.bankAccNo || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Bank Transfer Payable Name:</strong> {agentDetails?.name || 'N/A'}
              </Typography>
            </Grid>
          </Box>

          <Box sx={{ marginBottom: 3 }}>
            {/* Agent Section */}
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
              2. Landlord (Optional)
            </Typography>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Bank Name:</strong> {rentalClaimRequest?.landlordBankName || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Account Number:</strong> {rentalClaimRequest?.landlordAccNo || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Bank Transfer Payable Name:</strong> {rentalClaimRequest?.landlordPayableName || 'N/A'}
              </Typography>
            </Grid>
          </Box>

          <Box>
            {/* Direct Leader Section */}
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
              3. Direct Leader
            </Typography>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Bank Name:</strong> {recruiterDetails?.bankName || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Account Number:</strong> {recruiterDetails?.bankAccNo || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Bank Transfer Payable Name:</strong> {recruiterDetails?.name || 'N/A'}
              </Typography>
            </Grid>
          </Box>
        </Box>
        <TermsConditionComponent/>
        {/* Submit Button */}
        {
          rentalDeal?.status !== 8 && (
            <Box textAlign="right" sx={{ marginTop: 3 }}>
              <Button variant="contained" color="primary" onClick={handleApproveClaim}>
                Approve
              </Button>
            </Box>
          )
        }
      </Box>
    </div>
  );
};

export default RentalClaimDetails;
