import { useEffect, useState } from 'react';
import { Box, Typography, Grid, TextField, Button, Select, MenuItem } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where, doc, getDoc, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { useAuth } from '../../../context/AuthContext';
import TermsConditionComponent from '../../../components/TermsConditionTable';
import ClaimHeaderComponent from '../../../components/ClaimHeaderComponent';
import { formatNumberWithCommas } from '../../../Helper/Helper';

const ClaimCommRental = () => {
  const { id } = useParams(); // Extract rentalDealId from the URL
  const { currentUser } = useAuth();
  const [rentalDeal, setRentalDeal] = useState(null);
  const [rentalClaim, setRentalClaim] = useState(null);
  const [agentClaim, setAgentClaim] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userBankDetails, setUserBankDetails] = useState(null);
  const [sstTo, setSSTTo] = useState();
  const navigate = useNavigate();
  const [recruiterBankDetails, setRecruiterBankDetails] = useState(null);
  const [otherAgents, setOtherAgents] = useState([]); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch rental deal
        const rentalDealsRef = collection(db, 'rentalDeals');
        const rentalDealQuery = query(rentalDealsRef, where('rentalDealId', '==', id));
        const rentalDealSnapshot = await getDocs(rentalDealQuery);
  
        if (!rentalDealSnapshot.empty) {
          // Fetch the first rental deal document
          const rentalDealDoc = rentalDealSnapshot.docs[0];
          setRentalDeal({ id: rentalDealDoc.id, ...rentalDealDoc.data() }); // Include document ID as `id`
        } else {
          console.error('No rental deal found for the provided rentalDealId.');
        }
  
        // Fetch rental claim
        const rentalClaimsRef = collection(db, 'rentalClaims');
        const rentalClaimQuery = query(rentalClaimsRef, where('rentalDealId', '==', id));
        const rentalClaimSnapshot = await getDocs(rentalClaimQuery);
  
        if (!rentalClaimSnapshot.empty) {
          // Fetch the first rental claim document
          const rentalClaimDoc = rentalClaimSnapshot.docs[0];
          const rentalClaimData = { id: rentalClaimDoc.id, ...rentalClaimDoc.data() };
          setRentalClaim(rentalClaimData);
  
          // Filter agent-specific claim
          const agentSpecificClaim = rentalClaimData.agentClaims.find(
            (claim) => claim.agentUid === currentUser.uid
          );
  
          if (agentSpecificClaim) {
            setAgentClaim(agentSpecificClaim);
  
            // Identify other agents working on the rental case
            const otherAgentsList = rentalClaimData.agentClaims.filter(
              (claim) => claim.agentUid !== currentUser.uid
            );
            setOtherAgents(otherAgentsList);
          } else {
            console.error('No matching claim found for the current user.');
          }
        } else {
          console.error('No rental claim found for the provided rentalDealId.');
        }
  
        // Fetch user's bank details from users collection
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
  
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUserBankDetails(userData); // Store user's bank details
  
          if (userData.recruiterId) {
            // Fetch recruiter's information
            const recruiterDocRef = doc(db, 'users', userData.recruiterId);
            const recruiterDocSnap = await getDoc(recruiterDocRef);
            if (recruiterDocSnap.exists()) {
              setRecruiterBankDetails(recruiterDocSnap.data()); // Store recruiter's bank details
            } else {
              console.error('Recruiter not found.');
            }
          }
        } else {
          console.error('No user data found for the current user.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [id, currentUser]);
  

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!setRentalClaim) {
    return <Typography>No matching claim found for the current user.</Typography>;
  }

  const handleBackButton = () => {
    navigate('/commissions');
  };

  const handleSubmitClaim = async () => {
    try {
      const landlordBankName = document.getElementById('landlordBankName').value;
      const landlordAccNo = document.getElementById('landlordAccNo').value;
      const landlordPayableName = document.getElementById('landlordPayableName').value;
  
      // Data to be stored in the rentalClaimRequest collection
      const newClaimRequest = {
        rentalDealsId: rentalDeal.id,
        createdBy: currentUser.uid,
        createdAt: new Date().toISOString(),
        updatedBy: currentUser.uid,
        updatedAt: new Date().toISOString(),
        status: 9,
        landlordBankName,
        landlordAccNo,
        landlordPayableName,
        commissionAmount: agentClaim.commissionAmount.toFixed(2),
        sstPayableTo: sstTo,
        rentalClaimId: rentalClaim?.id, // Include projectClaim document ID
        rentalDealId: rentalDeal?.rentalDealId, // projectDealId = PD-1, etc
        rentalId: rentalDeal?.id, // projectDeals document ID
      };
  
      // Add new claim request to Firestore
      const rentalClaimRequestRef = collection(db, 'rentalClaimRequest');
      await addDoc(rentalClaimRequestRef, newClaimRequest);
  
      // Update the rentalDeal status to 9
      const rentalDealRef = doc(db, 'rentalDeals', rentalDeal.id);
      await updateDoc(rentalDealRef, { status: 9 });
  
      alert('Claim submitted and stored successfully!');
      navigate('/commissions'); // Navigate back to commissions page
    } catch (error) {
      console.error('Error submitting claim:', error);
      alert('Failed to submit claim. Please try again.');
    }
  };
  
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackButton}
        sx={{ mb: 2, marginBottom: '50px' }}
      >
        Back
      </Button>
      <Box sx={{ padding: 3, maxWidth: 800, margin: 'auto', border: '1px solid #ddd', borderRadius: 2 }}>
      
      <ClaimHeaderComponent title="RENTAL CLAIM FORM"/>

        {/* Agent Claim Details */}
        <Box sx={{ padding: 3, marginBottom: 3, border: '1px solid #ddd', borderRadius: 2 }}>
          {/* Agent Details */}
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Agent Name:</strong> {agentClaim.agentName || 'Unknown'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Date:</strong> {new Date().toLocaleDateString()}
              </Typography>
            </Grid>
          </Grid>

          {/* Landlord and Tenant Information */}
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Landlord Name:</strong> {rentalDeal.landlordName || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Tenant Name:</strong> {rentalDeal.tenantName || 'N/A'}
              </Typography>
            </Grid>
          </Grid>

          {/* Project and Lot Information */}
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Project Name:</strong> {rentalDeal.projectName || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Lot/Unit Number:</strong> {rentalDeal.lotNumber || 'N/A'}
              </Typography>
            </Grid>
          </Grid>

          {/* Financial Details */}
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Rental Per Month:</strong> RM{rentalDeal.rentalPerMonth || 0}.00
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Booking Fees LSV Received:</strong> RM{rentalDeal.bookingFees || 0}.00
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Stamping Fees:</strong> RM{rentalDeal.stampingFees || 0}.00
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>6% SST:</strong> RM{rentalDeal.sst || 0}.00
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Rental Tenure:</strong> {rentalDeal.yearsOfTenure || 'N/A'} year(s)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Commission:</strong> {rentalDeal.serviceFee || 'N/A'} Month(s)
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Security Deposit:</strong> RM{rentalDeal.securityDeposit || 0}.00
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Utility Deposit:</strong> RM{rentalDeal.utilityDeposit || 0}.00
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Access Card Deposit:</strong> RM{rentalDeal.accessCardDeposit || 0}.00
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* Other Agent Information */}
              <Typography variant="body1" sx={{ marginBottom: 2 }}>
                <strong>Co-Broke:</strong>{' '}
                {otherAgents.length > 0
                  ? otherAgents.map((agent, index) => (
                      <span key={index}>
                        {agent.agentName}
                        {index < otherAgents.length - 1 ? ', ' : ''}
                      </span>
                    ))
                  : 'N/A'}
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>Total Rental Commission to be paid to agent (based on your tier):</strong> RM{formatNumberWithCommas(agentClaim.commissionAmount.toFixed(2))}
          </Typography>

          {/* Stamping Fees Payable To */}
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>SST Payable To:</strong>{' '}
            <Select
              value={sstTo}
              onChange={(e) => setSSTTo(e.target.value)}
              fullWidth
              displayEmpty
            >
              <MenuItem value="">N/A</MenuItem>
              <MenuItem value={currentUser.uid}>{agentClaim.agentName}</MenuItem>
              {otherAgents.map((agent) => (
                <MenuItem key={agent.agentUid} value={agent.agentUid}>
                  {agent.agentName}
                </MenuItem>
              ))}
            </Select>
          </Typography>
        </Box>

        <Box sx={{ marginBottom: 5, padding: 3, border: '1px solid #ddd', borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            Full Bank Information
          </Typography>
          <Box sx={{ marginBottom: 3 }}>
            {/* Agent Section */}
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
              1. Agent
            </Typography>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Bank Name:</strong> {userBankDetails?.bankName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Account Number:</strong> {userBankDetails?.bankAccNo}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Bank Transfer Payable Name:</strong> {userBankDetails?.name}
              </Typography>
            </Grid>
          </Box>

          <Box sx={{ marginBottom: 3 }}>
            {/* Landlord Section */}
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
              2. Landlord (optional)
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="landlordBankName" // Unique ID for fetching data
                  fullWidth
                  label="Bank Name"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="landlordAccNo" // Unique ID for fetching data
                  fullWidth
                  label="Account Number"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="landlordPayableName" // Unique ID for fetching data
                  fullWidth
                  label="Bank Transfer Payable Name"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Box>

          <Box>
            {/* Direct Leader Section */}
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
              3. Direct Leader
            </Typography>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Bank Name:</strong> {recruiterBankDetails?.bankName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Account Number:</strong> {recruiterBankDetails?.bankAccNo}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Bank Transfer Payable Name:</strong> {recruiterBankDetails?.name}
              </Typography>
            </Grid>
          </Box>
        </Box>
        <TermsConditionComponent/>
        {/* Submit Button */}
        {
          rentalDeal?.status !== 9 && (
            <Box textAlign="right" sx={{ marginTop: 3 }}>
              <Button variant="contained" color="primary" onClick={handleSubmitClaim}>
                Submit Claim
              </Button>
            </Box>
          )
        }
      </Box>
    </div>
  );
};

export default ClaimCommRental;

