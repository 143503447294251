import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, Button } from '@mui/material';
import { doc, getDoc,updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import TermsConditionComponent from '../../../components/TermsConditionTable';
import ClaimHeaderComponent from '../../../components/ClaimHeaderComponent';
import InfoGridComponent from '../../../components/InfoGridComponent';
import { fetchSalesAgents, fetchEmailTemplates } from '../../../services/Services';
import ConfirmationModal from '../../../components/ConfirmationModa';
import { sendEmail } from '../../../Helper/EmailNotification';
import { formatNumberWithCommas } from '../../../Helper/Helper';

const ClaimCommSubsale = () => {
  const { id } = useParams(); // Extract projectClaimRequest ID from the URL
  const [claimRequest, setClaimRequest] = useState(null);
  const [subsaleDeal, setSubsaleDeal] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [userBankDetails, setUserBankDetails] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [salesAgents, setSalesAgents] = useState({});

  useEffect(() => {
      const fetchData = async () => {
        try {
          // Fetch subsaleClaimRequest
          const claimRequestRef = doc(db, 'subsaleClaimRequest', id);
          const claimRequestSnap = await getDoc(claimRequestRef);
  
          if (claimRequestSnap.exists()) {
            const claimRequestData = claimRequestSnap.data();
            setClaimRequest(claimRequestData);
  
            // Fetch related subsaleDeal
            const subsaleDealRef = doc(db, 'subsaleDeals', claimRequestData.subsaleId);
            const subsaleDealSnap = await getDoc(subsaleDealRef);
  
            if (subsaleDealSnap.exists()) {
              setSubsaleDeal(subsaleDealSnap.data());
            }
  
            // Fetch sales agents and map their bank details
            const agents = await fetchSalesAgents();
            setSalesAgents(agents);
            const agent = agents.find(agent => agent.uid === claimRequestData.createdBy);
            if (agent) {
              setUserBankDetails({
                bankName: agent.bankName,
                bankAccNo: agent.bankAccNo,
                name: agent.name || 'N/A',
              });
            }
          } else {
            console.error('No claim request found with the given ID.');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, [id]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  const handleBackButton = () => {
    navigate('/requests', { state: { tabIndex: 1 } })
  };

  const handleApproveClaim = async () => {
    try {
      // Update claim request status to 8
      const claimRequestRef = doc(db, 'subsaleClaimRequest', id);
      await updateDoc(claimRequestRef, { status: 8 });

      // Update project deal  status to 8
      if (claimRequest?.subsaleId) {
        const subsaleDealRef = doc(db, 'subsaleDeals', claimRequest.subsaleId);
        await updateDoc(subsaleDealRef, { status: 8 });
  
        // Update local state to reflect the new status
        setSubsaleDeal((prevDeal) => ({
          ...prevDeal,
          status: 8,
        }));
      }
  
      // Update agentClaims in subsaleClaim to mark isClaimed as true for the matching agent
      if (claimRequest?.subsaleClaimId) {
        const subsaleClaimRef = doc(db, 'subsaleClaims', claimRequest.subsaleClaimId);
        const subsaleClaimSnap = await getDoc(subsaleClaimRef);
  
        if (subsaleClaimSnap.exists()) {
          const subsaleClaimData = subsaleClaimSnap.data();
          if (subsaleClaimData?.agentClaims?.length > 0) {
            const updatedAgentClaims = subsaleClaimData.agentClaims.map((claim) =>
              claim.agentUid === claimRequest.createdBy
                ? { ...claim, isClaimed: true }
                : claim
            );
  
            // Update the projectClaim document in Firestore
            await updateDoc(subsaleClaimRef, { agentClaims: updatedAgentClaims });
          }
        }
      }
  
      // Fetch email templates
      const emailTemplates = await fetchEmailTemplates();
  
      if (emailTemplates?.Claims_Approved) {
        const emailTemplate = emailTemplates.Claims_Approved;
        const personalizedMessage = emailTemplate.body
          .replace('{{dealId}}', claimRequest.subsaleDealId || 'N/A')
          .replace('{{commission}}', `${claimRequest.commissionAmount || '0.00'}`);
  
        // Send email to the agent
        const agent = salesAgents.find((agent) => agent.uid === claimRequest.createdBy);
        if (agent && agent.email) {
          const userDetails = {
            name: agent.name,
            email: agent.email, // Ensure email is fetched in the fetchSalesAgents service
          };
          await sendEmail(userDetails, personalizedMessage, emailTemplate.subject);
        }
      }
  
      alert('Claim approved successfully.');
      navigate('/requests', { state: { tabIndex: 1 } });
    } catch (error) {
      console.error('Error approving claim:', error);
      alert('Failed to approve the claim. Please try again.');
    }
  };

  if (loading) {
      return <Typography>Loading...</Typography>;
  }

  if (!claimRequest) {
    return <Typography>No claim request found.</Typography>;
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackButton}
        sx={{ mb: 2, marginBottom: '50px' }}
      >
        Back
      </Button>
      <Box sx={{ padding: 3, maxWidth: 800, margin: 'auto', border: '1px solid #ddd', borderRadius: 2 }}>

        {/* Header Section */}
        <ClaimHeaderComponent title="VIEW PROJECT CLAIM" />

        {/* Claim Request Details */}
        <Box sx={{ padding: 3, marginBottom: 3, border: '1px solid #ddd', borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            Claim Request Details
          </Typography>
          <InfoGridComponent
            items={[
              { title: "Agent Name", data: userBankDetails?.name || 'N/A' },
              { title: "Date", data: claimRequest?.createdAt ? new Date(claimRequest.createdAt).toLocaleDateString() : 'N/A' },
              { title: "Project Name", data: claimRequest?.projectName || 'N/A'},
              { title: "Purchaser Name", data: claimRequest?.purchaserName || 'N/A'},
              { title: "SPA Price", data: `RM${claimRequest?.spaPrice || '0.00'}` },
              { title: "Purchased Price", data: `RM${claimRequest?.purchasePrice || '0.00'}` },
              {title: "Subsale Deal ID", data: claimRequest.subsaleDealId || 'N/A' },
              { title: "Commission Amount", data: `RM${formatNumberWithCommas(claimRequest?.commissionAmount)}` },
            ]}
          />
        </Box>
        {/* Full Bank Information */}
        <Box sx={{ marginBottom: 5, padding: 3, border: '1px solid #ddd', borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            Full Bank Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Bank Name:</strong> {userBankDetails?.bankName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Account Number:</strong> {userBankDetails?.bankAccNo}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <Typography variant="body1">
              <strong>Bank Transfer Payable Name:</strong> {userBankDetails?.name}
            </Typography>
          </Grid>
        </Box>

        <TermsConditionComponent />
        {
          subsaleDeal?.status !== 8 && (
            <Box textAlign="right" sx={{ marginTop: 3 }}>
              <Button variant="contained" color="primary" onClick={handleApproveClaim}>
                Approve
              </Button>
            </Box>
          )
        }

        {/* Confirmation Modal */}
      <ConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={() => {
          handleApproveClaim();
          setModalOpen(false);
        }}
        title="Approve Claim Request"
        message="Are you sure you want to approve this claim request?"
      />
      </Box>
    </div>
  );
};

export default ClaimCommSubsale;
