import React from "react";
import { Grid, Typography } from "@mui/material";

const InfoGridComponent = ({ items = [] }) => {
  return (
    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
      {items.map((item, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <Typography variant="body1">
            <strong>{item.title}:</strong> {item.data || "N/A"}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default InfoGridComponent;
