import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import DocumentsPage from './pages/OfficialDocuments/DocumentsPage';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import HomePage from './pages/Home/HomePage';
import Layouts from './components/Layouts';
import UsersPage from './pages/UsersPage';
import ProfilePage from './pages/Profile/ProfilePage';
import ProjectListPage from './pages/Projects/ProjectListPage';
import PropertyDealsPage from './pages/Sales/PropertyDealsPage';
import CreateProjectDealPage from './pages/Sales/Create/CreateProjectDealPage';
import CreateRentalDealPage from './pages/Sales/Create/CreateRentalDealPage';
import CreateSubsaleDealPage from './pages/Sales/Create/CreateSubsaleDealPage';
import EditRentalDealPage from './pages/Sales/Edit/EditRentalDealPage';
import ViewRentalPage from './pages/Sales/View/ViewRentalDealPage';
import EditProjectDealPage from './pages/Sales/Edit/EditProjectDealPage';
import ViewProjectDealPage from './pages/Sales/View/ViewProjectDealPage';
import EditSubsaleDealPage from './pages/Sales/Edit/EditSubsaleDealPage';
import ViewSubsaleDealPage from './pages/Sales/View/ViewSubsaleDealPage';
import CommissionsPage from './pages/Commissions/CommissionsPage';
import RequestPage from './pages/Requests/RequestPage';
import ViewRequestProject from './pages/Requests/Conversions/ViewRequestProject';
import ViewRequestRental from './pages/Requests/Conversions/ViewRequestRental';
import ViewRequestSubsale from './pages/Requests/Conversions/ViewRequestSubsale';
import ViewCommProject from './pages/Commissions/View/ViewCommProject';
import ViewCommRental from './pages/Commissions/View/ViewCommRental';
import ViewCommSubsale from './pages/Commissions/View/ViewCommSubsale';
import ViewAnnouncementComponent from './pages/Home/ViewAnnouncementComponent';
import OfficeSlotsPage from './pages/OfficeSlots/OfficeSlotsPage';
import ContactPage from './pages/Contacts/ContactsPage';
import LeavesPage from './pages/Leaves/LeavesPage';
import RecruitmentPage from './pages/Recruitments/RecruitmentsPage';
import AdminRoute from './context/AdminRoute';
import ClaimCommProject from './pages/Commissions/Claim/ClaimCommProject';
import ClaimCommRental from './pages/Commissions/Claim/ClaimCommRental';
import ClaimCommSubsale from './pages/Commissions/Claim/ClaimCommSubsale';
import ViewClaimsRental from './pages/Requests/Claims/ViewClaimsRental';
import ViewClaimsProject from './pages/Requests/Claims/ViewClaimsProject';
import ViewClaimsSubsale from './pages/Requests/Claims/ViewClaimsSubsale';
import EmailTemplate from './pages/Email/EmailTemplate';

const App = () => {

  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public routes without Layouts */}
          <Route path="/login" element={<LoginPage />} />
          {/* Routes with Layouts for authenticated users only */}
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <Layouts>
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/sales" element={<PropertyDealsPage />}/>
                    <Route path="/sales/create-project-deal" element={<CreateProjectDealPage/>} />
                    <Route path="/sales/create-rental-deal" element={<CreateRentalDealPage/>} />
                    <Route path="/sales/create-subsale-deal" element={<CreateSubsaleDealPage/>} />
                    <Route path="/sales/edit-project-deal/:id" element={<EditProjectDealPage />} />
                    <Route path="/sales/edit-rental-deal/:id" element={<EditRentalDealPage />} />
                    <Route path="/sales/edit-subsale-deal/:id" element={<EditSubsaleDealPage />} />
                    <Route path="/sales/view-project-deal/:id" element={<ViewProjectDealPage />} />
                    <Route path="/sales/view-rental-deal/:id" element={<ViewRentalPage />} />
                    <Route path="/sales/view-subsale-deal/:id" element={<ViewSubsaleDealPage />} />
                    <Route path="/request/view-project-deal/:id" element={<ViewRequestProject />} />
                    <Route path="/request/view-rental-deal/:id" element={<ViewRequestRental />} />
                    <Route path="/request/view-subsale-deal/:id" element={<ViewRequestSubsale />} />
                    <Route path="/commissions/view-project-commission/:id" element={<ViewCommProject />} />
                    <Route path="/commissions/view-rental-commission/:id" element={<ViewCommRental />} />
                    <Route path="/commissions/view-subsale-commission/:id" element={<ViewCommSubsale />} />
                    <Route path="/announcement/:id" element={<ViewAnnouncementComponent />} />
                    <Route path="/commissions" element={<CommissionsPage />} />
                    <Route path="/commissions/claim-project-commission/:id" element={<ClaimCommProject/>} />
                    <Route path="/commissions/claim-rental-commission/:id" element={<ClaimCommRental />} />
                    <Route path="/commissions/claim-subsale-commission/:id" element={<ClaimCommSubsale />} />
                    {/**Requests - Claims */}
                    <Route path="/request/view-project-claim/:id" element={<ViewClaimsProject />} />
                    <Route path="/request/view-subsale-claim/:id" element={<ViewClaimsSubsale />} />
                    <Route path="/request/view-rental-claim/:id" element={<ViewClaimsRental />} />
                    <Route
                      path="/requests"
                      element={
                        <AdminRoute>
                          <RequestPage />
                        </AdminRoute>
                      }
                    />
                    <Route path="/documents" element={<DocumentsPage />} />
                    <Route path='/office-slots' element={<OfficeSlotsPage/>} />
                    <Route path='/contacts' element={<ContactPage/>} />
                    <Route path='/leaves' element={<LeavesPage/>} />
                    <Route path='/recruitment' element={<RecruitmentPage/>} />
                    <Route
                      path="/users"
                      element={
                        <AdminRoute>
                          <UsersPage />
                        </AdminRoute>
                      }
                    />
                    <Route path="/profile" element={<ProfilePage/>} />  
                    <Route path="/projects" element={<ProjectListPage/>} />
                    <Route
                      path="/email-template"
                      element={
                        <AdminRoute>
                          <EmailTemplate/>
                        </AdminRoute>
                      }
                    />
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </Layouts>
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
