import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { db } from '../../firebase';
import { collection, doc, setDoc, getDocs } from 'firebase/firestore';

const EmailTemplate = () => {
  const [templates, setTemplates] = useState({
    Sales: {
      Approved: { subject: '', body: '' },
      Converted: { subject: '', body: '' },
      Returned: { subject: '', body: '' },
    },
    Claims: {
      Approved: { subject: '', body: '' },
      Returned: { subject: '', body: '' },
    },
    Others: {
      Approved: { subject: '', body: '' },
      Returned: { subject: '', body: '' },
    },
  });

  const [originalTemplates, setOriginalTemplates] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'EmailTemplate'));
        const loadedTemplates = { ...templates };
        const loadedOriginalTemplates = {};

        querySnapshot.forEach((doc) => {
          const [category, templateName] = doc.id.split('_');
          if (loadedTemplates[category] && loadedTemplates[category][templateName]) {
            loadedTemplates[category][templateName] = doc.data();
            loadedOriginalTemplates[doc.id] = doc.data();
          }
        });

        setTemplates(loadedTemplates);
        setOriginalTemplates(loadedOriginalTemplates);
      } catch (error) {
        console.error('Error fetching email templates:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  const handleSave = async (category, templateName) => {
    try {
      const templateData = templates[category][templateName];
      const docId = `${category}_${templateName}`;
      await setDoc(doc(db, 'EmailTemplate', docId), templateData);
      console.log(`Saved ${category} - ${templateName} template`);
      alert(`${category} - ${templateName} template saved successfully!`);

      // Update the original templates to reflect the latest saved version
      setOriginalTemplates((prev) => ({
        ...prev,
        [docId]: { ...templateData },
      }));
    } catch (error) {
      console.error(`Error saving ${category} - ${templateName} template:`, error);
      alert(`Failed to save ${category} - ${templateName} template. Please try again.`);
    }
  };

  const handleChange = (category, templateName, field, value) => {
    setTemplates((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [templateName]: {
          ...prev[category][templateName],
          [field]: value,
        },
      },
    }));
  };

  const hasChanges = (category, templateName) => {
    const docId = `${category}_${templateName}`;
    const original = originalTemplates[docId] || {};
    const current = templates[category][templateName] || {};
    return (
      original.subject !== current.subject ||
      original.body !== current.body
    );
  };

  const renderTemplate = (category, templateName) => (
    <Box
      sx={{
        marginBottom: 4,
        padding: 2,
        border: '1px solid #ddd',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
      }}
      key={`${category}_${templateName}`}
    >
      <Typography  variant="h6" sx={{ marginBottom: 2 }}>
        {templateName}
      </Typography>
      <TextField
        label="Subject"
        variant="outlined"
        fullWidth
        value={templates[category][templateName].subject}
        onChange={(e) => handleChange(category, templateName, 'subject', e.target.value)}
        sx={{ marginBottom: 2 }}
      />
      <TextField
        label="Body"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={templates[category][templateName].body}
        onChange={(e) => handleChange(category, templateName, 'body', e.target.value)}
        sx={{ marginBottom: 2 }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave(category, templateName)}
        >
          {hasChanges(category, templateName) ? 'Update' : 'Save'}
        </Button>
      </Box>
    </Box>
  );

  const renderCategory = (category) => (
    <Box key={category} sx={{ marginBottom: 6 }}>
      <Typography variant="h5" gutterBottom>
        {category}
      </Typography>
      {Object.keys(templates[category]).map((templateName) =>
        renderTemplate(category, templateName)
      )}
    </Box>
  );

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Email Template List
      </Typography>
      {Object.keys(templates).map((category) => renderCategory(category))}
    </Box>
  );
};

export default EmailTemplate;
