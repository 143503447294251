import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, Button } from '@mui/material';
import { doc, getDoc,updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import ClaimHeaderComponent from '../../../components/ClaimHeaderComponent';
import InfoGridComponent from '../../../components/InfoGridComponent';
import TermsConditionComponent from '../../../components/TermsConditionTable';
import { fetchSalesAgents, fetchEmailTemplates } from '../../../services/Services';
import ConfirmationModal from '../../../components/ConfirmationModa';
import { sendEmail } from '../../../Helper/EmailNotification';
import { formatNumberWithCommas } from '../../../Helper/Helper';

const ViewClaimsProject = () => {
  const { id } = useParams(); // ID from projectClaimRequest
  const navigate = useNavigate();
  const [projectClaimRequest, setProjectClaimRequest] = useState(null);
  const [projectDeal, setProjectDeal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [salesAgents, setSalesAgents] = useState({});
  const [agentBankDetails, setAgentBankDetails] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch projectClaimRequest
        const claimRequestRef = doc(db, 'projectClaimRequest', id);
        const claimRequestSnap = await getDoc(claimRequestRef);

        if (claimRequestSnap.exists()) {
          const claimRequestData = claimRequestSnap.data();
          setProjectClaimRequest(claimRequestData);

          // Fetch related projectDeal
          const projectDealRef = doc(db, 'projectDeals', claimRequestData.projectId);
          const projectDealSnap = await getDoc(projectDealRef);

          if (projectDealSnap.exists()) {
            setProjectDeal(projectDealSnap.data());
          }

          // Fetch sales agents and map their bank details
          const agents = await fetchSalesAgents();
          setSalesAgents(agents);
          const agent = agents.find(agent => agent.uid === claimRequestData.createdBy);
          if (agent) {
            setAgentBankDetails({
              bankName: agent.bankName,
              bankAccNo: agent.bankAccNo,
              name: agent.name || 'N/A',
            });
          }
        } else {
          console.error('No claim request found with the given ID.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!projectClaimRequest) {
    return <Typography>No claim request found.</Typography>;
  }

  const handleBackButton = () => {
    navigate('/requests', { state: { tabIndex: 1 } })
  };

  const handleApproveClaim = async () => {
    try {
      // Update claim request status to 8
      const claimRequestRef = doc(db, 'projectClaimRequest', id);
      await updateDoc(claimRequestRef, { status: 8 });

      // Update project deal  status to 8
      if (projectClaimRequest?.projectId) {
        const projectDealRef = doc(db, 'projectDeals', projectClaimRequest.projectId);
        await updateDoc(projectDealRef, { status: 8 });
  
        // Update local state to reflect the new status
        setProjectDeal((prevDeal) => ({
          ...prevDeal,
          status: 8,
        }));
      }
  
      // Update agentClaims in projectClaim to mark isClaimed as true for the matching agent
      if (projectClaimRequest?.projectClaimId) {
        const projectClaimRef = doc(db, 'projectClaims', projectClaimRequest.projectClaimId);
        const projectClaimSnap = await getDoc(projectClaimRef);
  
        if (projectClaimSnap.exists()) {
          const projectClaimData = projectClaimSnap.data();
          if (projectClaimData?.agentClaims?.length > 0) {
            const updatedAgentClaims = projectClaimData.agentClaims.map((claim) =>
              claim.agentUid === projectClaimRequest.createdBy
                ? { ...claim, isClaimed: true }
                : claim
            );
  
            // Update the projectClaim document in Firestore
            await updateDoc(projectClaimRef, { agentClaims: updatedAgentClaims });
          }
        }
      }
  
      // Fetch email templates
      const emailTemplates = await fetchEmailTemplates();
  
      if (emailTemplates?.Claims_Approved) {
        const emailTemplate = emailTemplates.Claims_Approved;
        const personalizedMessage = emailTemplate.body
          .replace('{{dealId}}', projectClaimRequest.projectDealId || 'N/A')
          .replace('{{commission}}', `${projectClaimRequest.commissionAmount || '0.00'}`);
  
        // Send email to the agent
        const agent = salesAgents.find((agent) => agent.uid === projectClaimRequest.createdBy);
        if (agent && agent.email) {
          const userDetails = {
            name: agent.name,
            email: agent.email, // Ensure email is fetched in the fetchSalesAgents service
          };
          await sendEmail(userDetails, personalizedMessage, emailTemplate.subject);
        }
      }
  
      alert('Claim approved successfully.');
      navigate('/requests', { state: { tabIndex: 1 } });
    } catch (error) {
      console.error('Error approving claim:', error);
      alert('Failed to approve the claim. Please try again.');
    }
  };
  
  
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackButton}
        sx={{ mb: 2, marginBottom: '50px' }}
      >
        Back
      </Button>
      <Box sx={{ padding: 3, maxWidth: 800, margin: 'auto', border: '1px solid #ddd', borderRadius: 2 }}>
        {/* Header Section */}
        <ClaimHeaderComponent title="VIEW PROJECT CLAIM" />

        {/* Claim Details */}
        <Box sx={{ padding: 3, marginBottom: 3, border: '1px solid #ddd', borderRadius: 2 }}>
          <InfoGridComponent
            items={[
              { title: "Agent Name", data: agentBankDetails?.name || 'N/A' },
              { title: "Date", data: projectClaimRequest?.createdAt ? new Date(projectClaimRequest.createdAt).toLocaleDateString() : 'N/A' },
              {title: "Project Name", data: projectClaimRequest.projectName || 'N/A' },
              {title: "Purchaser Name", data: projectClaimRequest.purchaserName || 'N/A' },
              {title: "SPA Price", data: `RM${projectClaimRequest.spaPrice || '0.00'}` },
              {title: "Nett Price", data: `RM${projectClaimRequest.nettPrice || '0.00'}` },
              {title: "Project Deal ID", data: projectClaimRequest.projectDealId || 'N/A' },
              {title: "Commission Amount", data: `RM${formatNumberWithCommas(projectClaimRequest.commissionAmount) || '0.00'}`}
            ]}
          />
        </Box>

        {/* Full Bank Information */}
        <Box sx={{ marginBottom: 5, padding: 3, border: '1px solid #ddd', borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            Full Bank Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Bank Name:</strong> {agentBankDetails?.bankName || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Account Number:</strong> {agentBankDetails?.bankAccNo || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <Typography variant="body1">
              <strong>Bank Transfer Payable Name:</strong> {agentBankDetails?.name || 'N/A'}
            </Typography>
          </Grid>
        </Box>

        <TermsConditionComponent />

        {
          projectDeal?.status !== 8 && (
            <Box textAlign="right" sx={{ marginTop: 3 }}>
              <Button variant="contained" color="primary" onClick={handleApproveClaim}>
                Approve
              </Button>
            </Box>
          )
        }
        

      {/* Confirmation Modal */}
      <ConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={() => {
          handleApproveClaim();
          setModalOpen(false);
        }}
        title="Approve Claim Request"
        message="Are you sure you want to approve this claim request?"
      />
      </Box>
    </div>
  );
};

export default ViewClaimsProject;
