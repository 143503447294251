import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Button, Paper, TextField } from '@mui/material';
import TableComponent from '../../components/TableComponent'; 
import VisibilityIcon from '@mui/icons-material/Visibility'; 
import EditIcon from '@mui/icons-material/Edit'; 
import DeleteIcon from '@mui/icons-material/Delete'; 
import { useNavigate } from 'react-router-dom';
import { collection, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase'; 
import { useAuth } from '../../context/AuthContext';
import { StatusWrapper } from '../../components/StatusWrapper';
import ConfirmationModal from '../../components/ConfirmationModa';

const Rental = () => {
  const navigate = useNavigate();
  const { currentUser, userData } = useAuth(); 
  const [rentalData, setRentalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For filtered data
  const [searchQuery, setSearchQuery] = useState(''); // For search input
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchRentalData = () => {
      const statusCollection = collection(db, 'status');
      const rentalCollection = collection(db, 'rentalDeals');
  
      // Subscribe to real-time updates for statuses
      const unsubscribeStatus = onSnapshot(statusCollection, (snapshot) => {
        const statusMap = snapshot.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data().Name; // Map status ID to name
          return acc;
        }, {});
  
        // Subscribe to real-time updates for rentalDeals
        const unsubscribeRentals = onSnapshot(rentalCollection, (snapshot) => {
          const rentals = snapshot.docs.map((doc, index) => ({
            no: index + 1,
            id: doc.id,
            rentalDealId: doc.rentalDealId, // Use the document ID as Rental Order ID
            landlordName: doc.data().landlordName,
            projectName: doc.data().projectName,
            lotNumber: doc.data().lotNumber,
            rentalPerMonth: doc.data().rentalPerMonth,
            status: doc.data().status,
            status1: statusMap[doc.data().status] || 'Unknown Status', // Replace or calculate status as needed
            ...doc.data(),
          }));
  
          if (userData.position === 'Admin') {
            setRentalData(rentals);
            setFilteredData(rentals); // Initialize filtered data
          } else {
            const userProjects = rentals
              .filter((rental) => rental.createdBy === currentUser.uid)
              .sort((a, b) => a.rentalDealId.localeCompare(b.rentalDealId)); // Sort by rentalDealId
            setRentalData(userProjects);
            setFilteredData(userProjects); // Initialize filtered data
          }
  
          setLoading(false); // Stop loading once data is fetched
        });
  
        return unsubscribeRentals; // Return the rental unsubscribe function
      });
  
      return () => {
        // Cleanup on component unmount
        unsubscribeStatus();
      };
    };
  
    fetchRentalData();
  }, [currentUser.uid, userData.position]);

  // Update filtered data when searchQuery changes
  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = rentalData.filter((item) =>
      item.landlordName.toLowerCase().includes(lowerCaseQuery) ||
      item.projectName.toLowerCase().includes(lowerCaseQuery) ||
      item.lotNumber.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredData(filtered);
  }, [searchQuery, rentalData]);

  // Handlers for the action buttons
  const handleView = (row) => {
    navigate(`/sales/view-rental-deal/${row.id}`);
  };

  const handleEdit = (row) => {
    navigate(`/sales/edit-rental-deal/${row.id}`);
  };

  const handleDelete = async () => {
    if (selectedRow) {
      try {
        // Delete the document from Firestore
        await deleteDoc(doc(db, 'rentalDeals', selectedRow.id));
        // Update the local state to remove the deleted item
        setRentalData((prevData) => prevData.filter((item) => item.id !== selectedRow.id));
        setFilteredData((prevData) => prevData.filter((item) => item.id !== selectedRow.id)); // Update filtered data
        setConfirmationModalOpen(false); // Close the confirmation dialog
        setSelectedRow(null); // Clear the selected row
      } catch (error) {
        console.error('Error deleting document:', error);
      }
    }
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setConfirmationModalOpen(true); // Open the confirmation dialog
  };

  const handleCloseModal = () => {
    setConfirmationModalOpen(false);
    setSelectedRow(null);
  };

  const handleAddNewDeal = () => {
    navigate('/sales/create-rental-deal');
  };

  // Define columns for the table
  const columns = [
    { id: 'no', label: 'No.', minWidth: 50 },
    { id: 'rentalDealId', label: 'Rental Order ID', minWidth: 150 },
    { id: 'landlordName', label: 'Landlord Name', minWidth: 150 },
    { id: 'projectName', label: 'Project Name', minWidth: 150 },
    { id: 'lotNumber', label: 'Lot Number', minWidth: 150 },
    { id: 'rentalPerMonth', label: 'Rental Per Month', minWidth: 150 },
    {
      id: 'status1',
      label: 'Status',
      minWidth: 150,
      render: (row) => StatusWrapper(row.status, row.status1),
    },
    {
      id: 'action',
      label: 'Action',
      minWidth: 150,
      render: (row) => (
        <Box>
          <IconButton color="primary" onClick={() => handleView(row)}>
            <VisibilityIcon />
          </IconButton>
          {row.status !== 3 && row.status !== 4 && row.status !== 10 && row.status !== 8 && row.status !== 7 && (
              <IconButton color="black" onClick={() => handleEdit(row)}>
                <EditIcon />
              </IconButton>
          )}
          {row.status !== 3 && row.status !== 4 && row.status !== 10 && row.status !== 8 && row.status !== 7 && (
              <IconButton color="error" onClick={() => handleDeleteClick(row)}>
                <DeleteIcon />
              </IconButton>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Paper>
      <Typography variant="h6" gutterBottom sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Rental Deal List
        <Button variant="contained" color="primary" onClick={handleAddNewDeal}>
          Add New
        </Button>
      </Typography>

      {/* Search Input */}
      <TextField
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{
          width: '250px',
          padding: '10px',
          marginBottom: '10px',
          '.MuiInputBase-root': {
            height: '40px', // Set a shorter height for the input field
          },
        }}
      />

      <TableComponent columns={columns} data={filteredData} loading={loading} />

      {/* Confirmation Dialog */}
      <ConfirmationModal
        open={confirmationModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete this rental deal? This action cannot be undone."
      />
    </Paper>
  );
};

export default Rental;
