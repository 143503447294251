import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  Button,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import { GENDERDATA, POSITIONS } from '../constants/data';
import { generateRandomPassword, formatDate } from '../Helper/Helper';
import useAuth from '../hooks/useAuth';
import { db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { fetchUsers } from '../Helper/FirebaseHelper';
import {sendEmail} from '../Helper/EmailNotification'
import TableComponent from '../components/TableComponent';
import axios from 'axios';
import CustomModal from '../components/CustomModal';

// Define table columns
const columns = [
  { id: 'no', label: 'No.', minWidth: 50 },
  { id: 'name', label: 'Name', minWidth: 150 },
  { id: 'email', label: 'Email', minWidth: 100 },
  { id: 'position', label: 'Position', minWidth: 150 },
  { id: 'subsalesTier', label: 'Subsale Tier', minWidth: 150 },
  { id: 'projectTier', label: 'Project Tier', minWidth: 150 },
  { id: 'joinedDate', label: 'Joined Date', minWidth: 150 },
];

export default function UsersPage() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const { user: currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    password: '',
    gender: GENDERDATA[0],
    position: POSITIONS[0],
    ic: '',
    contact: '',
    joinedDate: '',
    recruiterId: currentUser ? currentUser.uid : '',
    recruiterName: currentUser ? currentUser.displayName || '' : '',
    downline: [],
    insertedAt: new Date(),
    updatedAt: new Date(),
    updatedBy: currentUser ? currentUser.displayName || '' : '',
    salary: 0,
    medLeaves: 0,
    annualLeaves: 0,
    subsalesTier: 70,
    projectTier: 0,
    imageUrl: '',
    isDeleted: 0,
    deletedBy: '',
    deletedAt: '',
    tshirtSize: '',
    bankName: '',
    bankAccNo: '',
    isFirstTime: 1,
  });

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const usersList = await fetchUsers();
        const formattedUsers = usersList.map((user) => ({
          ...user,
          joinedDate: user.joinedDate ? formatDate(user.joinedDate) : 'N/A',
        }));
        setRows(formattedUsers);
      } catch (error) {
        console.error('Error fetching users: ', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setError(null);
    setSuccessMessage('');
    setNewUser({
      ...newUser,
      name: '',
      email: '',
      password: '',
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleAddUser = async () => {
    setLoading(true);
    setError(null);
  
    try {
      // Generate a random password with both plain and hashed versions
      const { plainPassword, hashedPassword } = await generateRandomPassword();
  
      // Firebase REST API endpoint for user creation
      const firebaseAuthEndpoint = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${process.env.REACT_APP_FIREBASE_API_KEY}`;
  
      // Create user via Firebase REST API
      const response = await axios.post(firebaseAuthEndpoint, {
        email: newUser.email,
        password: plainPassword,
        returnSecureToken: false, // This prevents logging the user in
      });
  
      const userId = response.data.localId;
  
      const joinedDateTimestamp = newUser.joinedDate
        ? new Date(newUser.joinedDate).toISOString()
        : null;
  
      // Write user details to Firestore
      await setDoc(doc(db, 'users', userId), {
        ...newUser,
        uid: userId,
        joinedDate: joinedDateTimestamp,
        password: hashedPassword, // Save hashed password
        createdBy: currentUser.uid,
        updatedBy: currentUser.uid,
        insertedAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });
  
      // Send email with login credentials
      const message = `Welcome to LSV System! Your account has been created successfully.\n\n
          Login URL: http://localhost:3000/\n
          Email: ${newUser.email}\n
          Password: ${plainPassword}\n\n
          Please login and update your profile.`;
  
      const subject = 'Welcome to LSV System';
  
      await sendEmail(
        { name: newUser.name, email: newUser.email },
        message,
        subject
      );
  
      // Update the table with the new user
      setRows([
        ...rows,
        {
          ...newUser,
          id: userId,
          joinedDate: newUser.joinedDate ? formatDate(newUser.joinedDate) : 'N/A',
        },
      ]);
  
      setSuccessMessage('User added and email sent successfully.');
      handleClose();
    } catch (e) {
      setError(e.message || 'An unexpected error occurred. Please try again.');
      console.error('Error creating user:', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        User List
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Add User
        </Button>
      </Typography>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <CustomModal
        open={open}
        onClose={handleClose}
        onSave={handleAddUser}
        title="Add New User"
        saving={loading}
      >
      <TextField
            autoFocus
            margin="dense"
            label="Name"
            name="name"
            fullWidth
            value={newUser.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            type="email"
            fullWidth
            value={newUser.email}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="IC"
            name="ic"
            fullWidth
            value={newUser.ic}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Contact"
            name="contact"
            fullWidth
            value={newUser.contact}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Gender"
            name="gender"
            select
            fullWidth
            value={newUser.gender}
            onChange={handleChange}
            SelectProps={{ native: true }}
          >
            {GENDERDATA.map((gender) => (
              <option key={gender} value={gender}>
                {gender}
              </option>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Position"
            name="position"
            select
            fullWidth
            value={newUser.position}
            onChange={handleChange}
            SelectProps={{ native: true }}
          >
            {POSITIONS.map((position) => (
              <option key={position} value={position}>
                {position}
              </option>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Joined Date"
            name="joinedDate"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={newUser.joinedDate}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Salary"
            name="salary"
            type="number"
            fullWidth
            value={newUser.salary}
            onChange={handleChange}
          />
      </CustomModal>
      <TableComponent
        columns={columns}
        data={rows.map((row, index) => ({ ...row, no: index + 1 }))}
        loading={loading}
      />
    </Paper>
  );
}
