import React, { useState, useEffect, useRef } from 'react';
import {Typography,Paper,Button,TextField,Snackbar,Alert} from '@mui/material';
import { GENDERDATA } from '../../constants/data';
import { generateRandomPassword, formatDate } from '../../Helper/Helper';
import { db } from '../../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { fetchUsers } from '../../Helper/FirebaseHelper';
import { sendEmail } from '../../Helper/EmailNotification';
import { useAuth } from '../../context/AuthContext';
import Tree from 'react-d3-tree';
import axios from 'axios';
import CustomModal from '../../components/CustomModal';

export default function RecruitmentsPage() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const { currentUser, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [treeData, setTreeData] = useState(null);
  const containerRef = useRef(null);

  // Ensure newUser starts as an independent object
  const getDefaultNewUser = () => ({
    name: '',
    email: '',
    password: '',
    gender: GENDERDATA[0],
    position: 'Negotiator',
    ic: '',
    contact: '',
    joinedDate: '',
    recruiterId: currentUser?.uid || '',
    recruiterName: userData?.name || '',
    downline: [],
    insertedAt: new Date(),
    updatedAt: new Date(),
    updatedBy: currentUser?.uid || '',
    salary: 0,
    medLeaves: 0,
    annualLeaves: 0,
    subsalesTier: 70,
    projectTier: 0,
    imageUrl: '',
    isDeleted: 0,
    deletedBy: '',
    deletedAt: '',
    tshirtSize: '',
    bankName: '',
    bankAccNo: '',
    isFirstTime: 1,
  });

  const [newUser, setNewUser] = useState(getDefaultNewUser);

  // Calculate the center of the container dynamically
  const calculateTranslate = () => {
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const containerHeight = containerRef.current?.offsetHeight || 0;
    return { x: containerWidth / 2, y: containerHeight / 2 - 150 }; // Subtract 50 to move it upwards
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const usersList = await fetchUsers();
        const formattedUsers = usersList.map((user) => ({
          ...user,
          joinedDate: user.joinedDate ? formatDate(user.joinedDate) : 'N/A',
        }));
        setRows(formattedUsers);

        const generateTreeData = (recruiterName, users) => {
          const buildTree = (recruiter) => {
            const recruits = users.filter((user) => user.recruiterName === recruiter.name);
            return {
              name: recruiter.name,
              children: recruits.map(buildTree),
            };
          };
          const recruiter = users.find((user) => user.name === recruiterName);
          return recruiter ? buildTree(recruiter) : null;
        };

        const treeData = generateTreeData(userData?.name || '', formattedUsers);
        setTreeData(treeData);
      } catch (error) {
        console.error('Error fetching users: ', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [userData]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setError(null);
    setSuccessMessage('');
    setNewUser(getDefaultNewUser()); // Reset newUser to default
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleAddUser = async () => {
    setLoading(true);
    setError(null);
  
    try {
      // Generate a random password with both plain and hashed versions
      const { plainPassword, hashedPassword } = await generateRandomPassword();

      // Firebase REST API endpoint for creating users
      const firebaseAuthEndpoint = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${process.env.REACT_APP_FIREBASE_API_KEY}`;
  
      // Create the new user via REST API
      const response = await axios.post(firebaseAuthEndpoint, {
        email: newUser.email,
        password: plainPassword,
        returnSecureToken: false, // Prevents logging in the new user automatically
      });
  
      const userId = response.data.localId;
  
      const joinedDateTimestamp = newUser.joinedDate
        ? new Date(newUser.joinedDate).toISOString()
        : null;
  
      // Save the new user data to Firestore
      await setDoc(doc(db, 'users', userId), {
        ...newUser,
        uid: userId,
        password: hashedPassword, // Save hashed password
        joinedDate: joinedDateTimestamp,
        recruiterId: currentUser.uid,
        recruiterName: userData.name,
        createdBy: currentUser.uid,
        updatedBy: currentUser.uid,
        insertedAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });
  
      // Send email notifications
      const message = `Welcome to LSV System! Your account has been created successfully.\n\n
      Login URL: http://localhost:3000/\n
      Email: ${newUser.email}\n
      Password: ${plainPassword}\n\n
      Please login and update your profile.`;
  
      const subject = 'Welcome to LSV System';
  
      const recruiterMessage = `A new agent has been recruited successfully.\n\n
      Recruiter: ${userData.name}\n
      New Agent Name: ${newUser.name}\n
      New Agent Email: ${newUser.email}\n\n`;
  
      const recruiterSubject = 'New Agent Recruited';
  
      // Send email to the new user
      await sendEmail({ name: newUser.name, email: newUser.email }, message, subject);
  
      // Send email notification to the recruiter/admin
      await sendEmail({ name: 'LSV', email: 'lsvproperty888@gmail.com' }, recruiterMessage, recruiterSubject);
  
      // Update the rows for the table
      setRows((prevRows) => [
        ...prevRows,
        {
          ...newUser,
          id: userId,
          joinedDate: newUser.joinedDate ? formatDate(newUser.joinedDate) : 'N/A',
        },
      ]);
  
      setSuccessMessage('Agent added and email sent successfully.');
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error('Error adding user:', error);
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const renderCustomNode = ({ nodeDatum }) => {
    // Calculate dynamic width based on the length of the name
    const textWidth = Math.max(nodeDatum.name.length * 8, 100); // Minimum width is 100
    const rectWidth = textWidth + 20; // Add padding
  
    return (
      <g>
        <rect
          width={rectWidth}
          height="40"
          x={-rectWidth / 2}
          y="-20"
          fill="#dedede"
          rx="5"
        />
        <text fill="black" x="0" y="5" textAnchor="middle">
          {nodeDatum.name}
        </text>
      </g>
    );
  };
  

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}> 
      <Typography
        variant="h6"
        gutterBottom
        sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        My Recruitments
        <Button variant="contained" color="primary" onClick={handleOpen}>
          New Recruit
        </Button>
      </Typography>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <CustomModal
        open={open}
        onClose={handleClose}
        onSave={handleAddUser}
        title="Add New User"
        saving={loading}
      >
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            name="name"
            fullWidth
            value={newUser.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            type="email"
            fullWidth
            value={newUser.email}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Contact"
            name="contact"
            fullWidth
            value={newUser.contact}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Gender"
            name="gender"
            select
            fullWidth
            value={newUser.gender}
            onChange={handleChange}
            SelectProps={{ native: true }}
          >
            {GENDERDATA.map((gender) => (
              <option key={gender} value={gender}>
                {gender}
              </option>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Joined Date"
            name="joinedDate"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={newUser.joinedDate}
            onChange={handleChange}
          />
      </CustomModal>
      <div
      ref={containerRef}
      style={{
        height: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      {treeData && (
        <Tree
          data={treeData}
          orientation="vertical"
          renderCustomNodeElement={({ nodeDatum }) => (
            <g>
              <rect
                width={Math.max(nodeDatum.name.length * 8, 100) + 20}
                height="40"
                x={-(Math.max(nodeDatum.name.length * 8, 100) + 20) / 2}
                y="-20"
                fill="#dedede"
                rx="5"
              />
              <text fill="black" x="0" y="5" textAnchor="middle">
                {nodeDatum.name}
              </text>
            </g>
          )}
          zoomable={false}
          translate={calculateTranslate()}
          pathFunc="diagonal"
          nodeSize={{ x: 200, y: 100 }}
        />
      )}
    </div>
    </Paper>
  );
}
