import { useState, useEffect } from 'react';
import { Tab, Box, Tabs } from '@mui/material';
import { useLocation } from 'react-router-dom';
import TabPanel from '../../components/TabPanel';
import RequestCommissionComponent from './RequestCommissionComponent';
import RequestClaimComponent from './RequestClaimComponent';

const RequestPage = () => {
  const location = useLocation();
  const initialTab = location.state?.tabIndex || 0;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // Update tab if location state changes
    setValue(initialTab);
  }, [initialTab]);
  
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Request tabs"
        
      >
        <Tab label="Conversions" />
        <Tab label="Claims" />
      </Tabs>

      <TabPanel value={value} index={0}>
        <RequestCommissionComponent/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RequestClaimComponent/>
      </TabPanel>

    </Box>
  );
}

export default RequestPage