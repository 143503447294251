import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase"; // Adjust the path based on your file structure

/**
 * Fetch projects from the Firestore 'projects' collection.
 * @returns {Promise<Array>} Array of project objects with id and data.
 */
export const fetchProjects = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "projects"));
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching projects:", error);
    throw error;
  }
};

/**
 * Fetch sources from the Firestore 'sources' collection.
 * @returns {Promise<Array>} Array of source objects with id and data.
 */
export const fetchSources = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "sources"));
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching sources:", error);
    throw error;
  }
};

/**
 * Fetch sales agents from the Firestore 'salesAgents' collection.
 * @returns {Promise<Array>} Array of sales agent objects with id and data.
 */
export const fetchSalesAgents = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'users'));
      // Map through the documents and return the uid and name of each sales agent
      return querySnapshot.docs.map(doc => ({
        uid: doc.id,
        name: doc.data().name,
        email: doc.data().email,
        bankAccNo: doc.data().bankAccNo,
        bankName: doc.data().bankName,
      }));
    } catch (error) {
      console.error('Error fetching sales agents:', error);
      return [];
    }
};

/**
 * Fetch a specific project deal by ID.
 * @param {string} id - The ID of the project deal to fetch.
 * @returns {Promise<Object>} The project deal data.
 */
export const fetchProjectDealById = async (id) => {
    try {
      const docRef = doc(db, 'projectDeals', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        console.error('No such document!');
        return null;
      }
    } catch (error) {
      console.error('Error fetching project deal:', error);
      throw error;
    }
};

export const fetchSalesAgentsName = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'users'));
      const names = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        names[doc.id] = data.name;
      });
      return names; // Return an object with { uid: name }
    } catch (error) {
      console.error('Error fetching sales agents:', error);
      return {};
    }
  };

  /**
 * Fetches email templates from the Firestore "EmailTemplate" collection.
 * @returns {Promise<Object>} A promise that resolves to an object containing email templates.
 */
export const fetchEmailTemplates = async () => {
  try {
    const templatesCollection = collection(db, 'EmailTemplate');
    const querySnapshot = await getDocs(templatesCollection);
    const templates = {};
    querySnapshot.forEach((doc) => {
      templates[doc.id] = doc.data();
    });
    return templates;
  } catch (error) {
    console.error('Error fetching email templates:', error);
    throw new Error('Failed to fetch email templates');
  }
};

export const fetchProjectDetails = async (projectId) => {
  try {
    const projectRef = doc(db, "projects", projectId);
    const projectSnap = await getDoc(projectRef);

    if (projectSnap.exists()) {
      const data = projectSnap.data();
      return {
        name: data.name || "N/A",
        commissionPercentage: data.commissionPercentage || 0,
      };
    } else {
      console.error("Project not found!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching project details:", error);
    throw error; // Optionally throw the error to handle it in the caller
  }
};

export const fetchServiceFeeMonths = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'serviceFeeMonth'));
    const months = [];
    querySnapshot.forEach((doc) => {
      months.push(doc.data().month);
    });
    return months;
  } catch (error) {
    console.error('Error fetching service fee months:', error);
    throw error;
  }
};

export const fetchYearOfTenure = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'yearOfTenure'));
    const years = [];
    querySnapshot.forEach((doc) => {
      years.push(doc.data().year);
    });
    return years;
  } catch (error) {
    console.error('Error fetching service fee months:', error);
    throw error;
  }
};