import { TextField, Button } from "@mui/material";

export const renderDocumentField = (label, url) => (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      value={url ? 'View Document' : 'N/A'}
      InputProps={{
        readOnly: true,
        endAdornment: url && (
          <Button variant="text" href={url} target="_blank">
            View
          </Button>
        ),
      }}
      sx={{ mb: 2 }}
    />
  );