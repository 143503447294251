import React from "react";
import { Box, Typography } from "@mui/material";
import logo2 from "../assets/logo2.png"; // Adjust the path to your logo

const ClaimHeaderComponent = ({ title }) => {
  return (
    <>
      {/* Header Section */}
      <Box textAlign="center" sx={{ marginBottom: 3 }}>
        <img src={logo2} alt="Company Logo" style={{ maxHeight: 280 }} />
        <Typography variant="h6" gutterBottom>
          LSV PROPERTY GROUP SDN BHD
        </Typography>
        <Typography variant="body1">
          E-2-3A, Block E, CBD Perdana 3, Jalan Perdana, Cyber 12, <br />
          63000 Cyberjaya Selangor <br />
          Tel: 603-8689 0388 Fax: 603-8689 0377
        </Typography>
      </Box>

      {/* Title Section */}
      <Box textAlign="center" sx={{ marginBottom: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ textDecoration: "underline", fontWeight: "bold" }}
        >
          {title}
        </Typography>
      </Box>
    </>
  );
};

export default ClaimHeaderComponent;
