import React from 'react';
import { Paper, Typography } from '@mui/material';

const StickyNote = ({ status, comments }) => {
  // Only render if 'comments' exist and 'status' is 6
  if (!comments || status !== 6) return null;

  return (
    <Paper
      elevation={3}
      sx={{
        position: 'absolute',
        top: 16,
        right: 16,
        width: 800,
        padding: 2,
        backgroundColor: '#FFF176', // Yellow color
        color: '#000',
        borderRadius: '8px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
      }}
    >
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
        Admin Comments:
      </Typography>
      <Typography variant="body2">{comments}</Typography>
    </Paper>
  );
};

export default StickyNote;
