import React, { useEffect, useState } from 'react';
import { CircularProgress, Box, Grid, Typography, Button, Avatar, TextField, CardContent, Card } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, getDocs, collection, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { sendEmail } from '../../../Helper/EmailNotification';
import { formatNumberWithCommas } from '../../../Helper/Helper';
import { renderDocumentField } from '../../../components/RenderDocumentField';
import { fetchEmailTemplates } from '../../../services/Services';
import CustomModal from '../../../components/CustomModal';

const ViewRequestSubsale = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [subsaleData, setSubsaleData] = useState(null);
  const [agentNames, setAgentNames] = useState({});
  const [openModal, setOpenModal] = useState(false); // Modal state for comments
  const [comments, setComments] = useState(''); // State for admin comments
  const [isLoading, setIsLoading] = useState(false);
  const [usersData, setUsersData] = useState({});
  const [emailTemplates, setEmailTemplates] = useState({});

  useEffect(() => {
    const fetchSubsaleData = async () => {
      try {
        const docRef = doc(db, 'subsaleDeals', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setSubsaleData(docSnap.data());
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching subsale data:', error);
      }
    };

    const fetchUsersData = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const querySnapshot = await getDocs(usersCollection);
        const users = {};
        const names = {};

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          users[doc.id] = data; // Store full user data
          names[doc.id] = data.name; // Store agent names separately
        });

        setUsersData(users);
        setAgentNames(names);
      } catch (error) {
        console.error('Error fetching users data:', error);
      }
    };

    const loadEmailTemplates = async () => {
      try {
        const templates = await fetchEmailTemplates(); // Use the reusable service function
        setEmailTemplates(templates);
      } catch (error) {
        console.error('Error loading email templates:', error);
      }
    };

    fetchSubsaleData();
    fetchUsersData();
    loadEmailTemplates();
  }, [id]);

  if (!subsaleData) {
    return <Typography>Loading...</Typography>;
  }

  const handleBackButton = () => {
    navigate('/requests', { state: { tabIndex: 0 } });
  };

  const handleStatusUpdate = async (newStatus, adminComments = '') => {
    setIsLoading(true);
  
    try {
      const docRef = doc(db, 'subsaleDeals', id);
      await updateDoc(docRef, { status: newStatus, comments: adminComments });
      setSubsaleData((prevData) => ({ ...prevData, status: newStatus, comments: adminComments }));
  
      // Create claims for agents
      if (subsaleData.salesAgentShare && subsaleData.salesAgentShare.length > 0) {
        const totalCommissionAmount = Number(subsaleData.commissionAmount.replace(/,/g, '')) || 0;
  
        const emailTemplateKey = newStatus === 7 ? 'Sales_Approved' : 'Sales_Returned';
        const emailTemplate = emailTemplates[emailTemplateKey];
  
        if (emailTemplate) {
          // Use Promise.all for sending emails asynchronously
          await Promise.all(
            subsaleData.salesAgentShare.map(async (agent) => {
              const userDetails = usersData[agent.uid];
              if (userDetails && userDetails.email) {
                const personalizedMessage = emailTemplate.body
                .replace('{{salesId}}', subsaleData.subsaleDealId)
                .replace('{{comments}}', adminComments);
                await sendEmail(userDetails, personalizedMessage, emailTemplate.subject);
              } else {
                console.error(`User details not found or email missing for agent UID: ${agent.uid}`);
              }
            })
          );
        }
        console.log("email sent")
        const agentClaims = subsaleData.salesAgentShare.map((agent) => {
          const user = usersData[agent.uid];
          const subsaleTier = user?.subsalesTier / 100 || 0; // Convert subsaleTier to decimal
          const sharePercentage = Number(agent.sharePercentage) / 100 || 0;
  
          let commissionAmount = 0;
  
          // Commission Calculation
          if (subsaleData.salesAgentShare.length === 1) {
            // Single Agent
            commissionAmount = totalCommissionAmount * subsaleTier;
          } else {
            // Multiple Agents
            commissionAmount = totalCommissionAmount * subsaleTier * sharePercentage;
          }
  
          return {
            agentUid: agent.uid,
            agentName: user?.name || 'Unknown Agent',
            commissionAmount: parseFloat(commissionAmount.toFixed(2)),
            commissionPercentage: sharePercentage * 100,
            subsaleTier: subsaleTier * 100,
            isClaimed: false,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          };
        });
  
        // Save agent claims to Firebase
        const subsaleClaimRef = doc(db, 'subsaleClaims', id);
        await setDoc(subsaleClaimRef, {
          subsaleDealId: subsaleData.subsaleDealId,
          agentClaims: agentClaims,
          createdAt: new Date().toISOString(),
        });
  
        console.log('Subsale claims successfully created.');
      }
  
      alert(`Status updated to ${newStatus === 7 ? 'Converted.' : 'Returned with comments.'} `);
      navigate('/requests', { state: { tabIndex: 0 } });
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Failed to update status. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setComments(''); // Reset comments
  };

  const handleSubmitReturn = () => {
    handleStatusUpdate(6, comments); // Update status to Returned (6) with comments
    handleCloseModal(); // Close the modal
  };

  

  return (
    <Box sx={{ p: 3, border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#fff', position: 'relative' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackButton}
        sx={{ mb: 2, marginBottom: '50px' }}
      >
        Back
      </Button>
      <Typography variant="h6" gutterBottom sx={{ mb: 2, marginBottom: '20px' }}>
        Subsale Deal Information
      </Typography>
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Seller Name"
            variant="outlined"
            fullWidth
            value={subsaleData.landlordName || 'N/A'}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Buyer Name"
            variant="outlined"
            fullWidth
            value={subsaleData.purchaserName || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Project Name"
            variant="outlined"
            fullWidth
            value={subsaleData.projectName || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Lot Number"
            variant="outlined"
            fullWidth
            value={subsaleData.lotNumber || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </Grid>

        {/* Middle Column */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Booking Amount (RM)"
            variant="outlined"
            fullWidth
            value={formatNumberWithCommas(subsaleData.bookingAmount || 0)}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Purchase Price (RM)"
            variant="outlined"
            fullWidth
            value={formatNumberWithCommas(subsaleData.purchasePrice || 0)}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="SPA Price (RM)"
            variant="outlined"
            fullWidth
            value={formatNumberWithCommas(subsaleData.spaPrice || 0)}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Commission Percentage (%)"
            variant="outlined"
            fullWidth
            value={subsaleData.commissionPercentage || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Commission Amount (RM)"
            variant="outlined"
            fullWidth
            value={formatNumberWithCommas(subsaleData.commissionAmount || 0)}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label={subsaleData.rebate?.type === '%' ? "Rebate (%)" : "Rebate (RM)"}
            variant="outlined"
            fullWidth
            value={subsaleData.rebate?.value || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} sm={4}>
          {renderDocumentField("Booking Form", subsaleData.bookingFormUrl)}
          {renderDocumentField("SPA Copy", subsaleData.spaCopyUrl)}
          {renderDocumentField("Cancellation Form", subsaleData.cancellationFormUrl)}
        </Grid>
      </Grid>

      {/* Agents Information */}
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Agents Information
          </Typography>
          {subsaleData.salesAgentShare.map((agent, index) => (
            <Box key={index} display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Avatar sx={{ bgcolor: '#3f51b5', mr: 2 }}>
                {agentNames[agent.uid]?.[0] || 'A'}
              </Avatar>
              <Box>
                <Typography>
                  Sales Agent: {agentNames[agent.uid] || agent.uid}
                </Typography>
                <Typography>
                  Commission Share: {agent.sharePercentage}%
                </Typography>
              </Box>
            </Box>
          ))}
        </CardContent>
      </Card>

      {/* Status and Action Buttons */}
      <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleStatusUpdate(7)}
        >
          {isLoading ?  <CircularProgress /> : 'Convert'}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleOpenModal}
        >
          Return
        </Button>
      </Box>

      {/* Return Comments Modal */}
      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        onSave={handleSubmitReturn}
        title="Provide Comments for Return"
        saving={isLoading}
      >
        <TextField
          label="Comments"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
      </CustomModal>
    </Box>
  );
};

export default ViewRequestSubsale;
